import axios from '../utils/axios';

import {fetchUserauthSecuritychallengeListStart, fetchUserauthSecuritychallengeListSuccess, fetchUserauthSecuritychallengeListFailure} from '../features/userauth'
import {fetchUserauthSecuritychallengeItemStart, fetchUserauthSecuritychallengeItemSuccess, fetchUserauthSecuritychallengeItemFailure} from '../features/userauth'
import {createUserauthSecuritychallengeItemStart, createUserauthSecuritychallengeItemSuccess, createUserauthSecuritychallengeItemFailure} from '../features/userauth'
import {updateUserauthSecuritychallengeItemStart, updateUserauthSecuritychallengeItemSuccess, updateUserauthSecuritychallengeItemFailure} from '../features/userauth'
import {deleteUserauthSecuritychallengeItemStart, deleteUserauthSecuritychallengeItemSuccess, deleteUserauthSecuritychallengeItemFailure} from '../features/userauth'


// SECURITYCHALLENGE ACTIONS
export const fetchUserauthSecuritychallengeList = async (dispatch, info, key) => {
    try{
        dispatch(fetchUserauthSecuritychallengeListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/userauth/securitychallenge/`, { params: info.params, headers: info.headers });
        dispatch(fetchUserauthSecuritychallengeListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchUserauthSecuritychallengeListFailure({key, error: error.message}));
    }
}
export const fetchUserauthSecuritychallengeItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchUserauthSecuritychallengeItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/userauth/securitychallenge/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchUserauthSecuritychallengeItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchUserauthSecuritychallengeItemFailure({key, error: error.message}));
    }
}
export const createUserauthSecuritychallengeItem = async (dispatch, info, key) => {
    try{
        dispatch(createUserauthSecuritychallengeItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/userauth/securitychallenge/`, info.payload, { headers: info.headers });
        dispatch(createUserauthSecuritychallengeItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createUserauthSecuritychallengeItemFailure({key, error: error.message}));
    }
}
export const updateUserauthSecuritychallengeItem = async (dispatch, info, key) => {
    try{
        dispatch(updateUserauthSecuritychallengeItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/userauth/securitychallenge/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateUserauthSecuritychallengeItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateUserauthSecuritychallengeItemFailure({key, error: error.message}));
    }
}
export const deleteUserauthSecuritychallengeItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteUserauthSecuritychallengeItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/userauth/securitychallenge/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteUserauthSecuritychallengeItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteUserauthSecuritychallengeItemFailure({key, error: error.message}));
    }
}




