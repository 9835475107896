import axios from '../utils/axios';

import {fetchUsermanagementStarredListStart, fetchUsermanagementStarredListSuccess, fetchUsermanagementStarredListFailure} from '../features/usermanagement'
import {fetchUsermanagementStarredItemStart, fetchUsermanagementStarredItemSuccess, fetchUsermanagementStarredItemFailure} from '../features/usermanagement'
import {createUsermanagementStarredItemStart, createUsermanagementStarredItemSuccess, createUsermanagementStarredItemFailure} from '../features/usermanagement'
import {updateUsermanagementStarredItemStart, updateUsermanagementStarredItemSuccess, updateUsermanagementStarredItemFailure} from '../features/usermanagement'
import {deleteUsermanagementStarredItemStart, deleteUsermanagementStarredItemSuccess, deleteUsermanagementStarredItemFailure} from '../features/usermanagement'

import {fetchUsermanagementCourseenrollmentprogressListStart, fetchUsermanagementCourseenrollmentprogressListSuccess, fetchUsermanagementCourseenrollmentprogressListFailure} from '../features/usermanagement'
import {fetchUsermanagementCourseenrollmentprogressItemStart, fetchUsermanagementCourseenrollmentprogressItemSuccess, fetchUsermanagementCourseenrollmentprogressItemFailure} from '../features/usermanagement'
import {createUsermanagementCourseenrollmentprogressItemStart, createUsermanagementCourseenrollmentprogressItemSuccess, createUsermanagementCourseenrollmentprogressItemFailure} from '../features/usermanagement'
import {updateUsermanagementCourseenrollmentprogressItemStart, updateUsermanagementCourseenrollmentprogressItemSuccess, updateUsermanagementCourseenrollmentprogressItemFailure} from '../features/usermanagement'
import {deleteUsermanagementCourseenrollmentprogressItemStart, deleteUsermanagementCourseenrollmentprogressItemSuccess, deleteUsermanagementCourseenrollmentprogressItemFailure} from '../features/usermanagement'

import {fetchUsermanagementSeriesinterestListStart, fetchUsermanagementSeriesinterestListSuccess, fetchUsermanagementSeriesinterestListFailure} from '../features/usermanagement'
import {fetchUsermanagementSeriesinterestItemStart, fetchUsermanagementSeriesinterestItemSuccess, fetchUsermanagementSeriesinterestItemFailure} from '../features/usermanagement'
import {createUsermanagementSeriesinterestItemStart, createUsermanagementSeriesinterestItemSuccess, createUsermanagementSeriesinterestItemFailure} from '../features/usermanagement'
import {updateUsermanagementSeriesinterestItemStart, updateUsermanagementSeriesinterestItemSuccess, updateUsermanagementSeriesinterestItemFailure} from '../features/usermanagement'
import {deleteUsermanagementSeriesinterestItemStart, deleteUsermanagementSeriesinterestItemSuccess, deleteUsermanagementSeriesinterestItemFailure} from '../features/usermanagement'

import {fetchUsermanagementChannelmemberListStart, fetchUsermanagementChannelmemberListSuccess, fetchUsermanagementChannelmemberListFailure} from '../features/usermanagement'
import {fetchUsermanagementChannelmemberItemStart, fetchUsermanagementChannelmemberItemSuccess, fetchUsermanagementChannelmemberItemFailure} from '../features/usermanagement'
import {createUsermanagementChannelmemberItemStart, createUsermanagementChannelmemberItemSuccess, createUsermanagementChannelmemberItemFailure} from '../features/usermanagement'
import {updateUsermanagementChannelmemberItemStart, updateUsermanagementChannelmemberItemSuccess, updateUsermanagementChannelmemberItemFailure} from '../features/usermanagement'
import {deleteUsermanagementChannelmemberItemStart, deleteUsermanagementChannelmemberItemSuccess, deleteUsermanagementChannelmemberItemFailure} from '../features/usermanagement'

import {fetchUsermanagementForummemberListStart, fetchUsermanagementForummemberListSuccess, fetchUsermanagementForummemberListFailure} from '../features/usermanagement'
import {fetchUsermanagementForummemberItemStart, fetchUsermanagementForummemberItemSuccess, fetchUsermanagementForummemberItemFailure} from '../features/usermanagement'
import {createUsermanagementForummemberItemStart, createUsermanagementForummemberItemSuccess, createUsermanagementForummemberItemFailure} from '../features/usermanagement'
import {updateUsermanagementForummemberItemStart, updateUsermanagementForummemberItemSuccess, updateUsermanagementForummemberItemFailure} from '../features/usermanagement'
import {deleteUsermanagementForummemberItemStart, deleteUsermanagementForummemberItemSuccess, deleteUsermanagementForummemberItemFailure} from '../features/usermanagement'

import {fetchUsermanagementMemberListStart, fetchUsermanagementMemberListSuccess, fetchUsermanagementMemberListFailure} from '../features/usermanagement'
import {fetchUsermanagementMemberItemStart, fetchUsermanagementMemberItemSuccess, fetchUsermanagementMemberItemFailure} from '../features/usermanagement'
import {createUsermanagementMemberItemStart, createUsermanagementMemberItemSuccess, createUsermanagementMemberItemFailure} from '../features/usermanagement'
import {updateUsermanagementMemberItemStart, updateUsermanagementMemberItemSuccess, updateUsermanagementMemberItemFailure} from '../features/usermanagement'
import {deleteUsermanagementMemberItemStart, deleteUsermanagementMemberItemSuccess, deleteUsermanagementMemberItemFailure} from '../features/usermanagement'

import {fetchUsermanagementSecurityquestionListStart, fetchUsermanagementSecurityquestionListSuccess, fetchUsermanagementSecurityquestionListFailure} from '../features/usermanagement'
import {fetchUsermanagementSecurityquestionItemStart, fetchUsermanagementSecurityquestionItemSuccess, fetchUsermanagementSecurityquestionItemFailure} from '../features/usermanagement'
import {createUsermanagementSecurityquestionItemStart, createUsermanagementSecurityquestionItemSuccess, createUsermanagementSecurityquestionItemFailure} from '../features/usermanagement'
import {updateUsermanagementSecurityquestionItemStart, updateUsermanagementSecurityquestionItemSuccess, updateUsermanagementSecurityquestionItemFailure} from '../features/usermanagement'
import {deleteUsermanagementSecurityquestionItemStart, deleteUsermanagementSecurityquestionItemSuccess, deleteUsermanagementSecurityquestionItemFailure} from '../features/usermanagement'

import {fetchUsermanagementOtpverificationListStart, fetchUsermanagementOtpverificationListSuccess, fetchUsermanagementOtpverificationListFailure} from '../features/usermanagement'
import {fetchUsermanagementOtpverificationItemStart, fetchUsermanagementOtpverificationItemSuccess, fetchUsermanagementOtpverificationItemFailure} from '../features/usermanagement'
import {createUsermanagementOtpverificationItemStart, createUsermanagementOtpverificationItemSuccess, createUsermanagementOtpverificationItemFailure} from '../features/usermanagement'
import {updateUsermanagementOtpverificationItemStart, updateUsermanagementOtpverificationItemSuccess, updateUsermanagementOtpverificationItemFailure} from '../features/usermanagement'
import {deleteUsermanagementOtpverificationItemStart, deleteUsermanagementOtpverificationItemSuccess, deleteUsermanagementOtpverificationItemFailure} from '../features/usermanagement'

import {fetchUsermanagementGeneralprofileListStart, fetchUsermanagementGeneralprofileListSuccess, fetchUsermanagementGeneralprofileListFailure} from '../features/usermanagement'
import {fetchUsermanagementGeneralprofileItemStart, fetchUsermanagementGeneralprofileItemSuccess, fetchUsermanagementGeneralprofileItemFailure} from '../features/usermanagement'
import {createUsermanagementGeneralprofileItemStart, createUsermanagementGeneralprofileItemSuccess, createUsermanagementGeneralprofileItemFailure} from '../features/usermanagement'
import {updateUsermanagementGeneralprofileItemStart, updateUsermanagementGeneralprofileItemSuccess, updateUsermanagementGeneralprofileItemFailure} from '../features/usermanagement'
import {deleteUsermanagementGeneralprofileItemStart, deleteUsermanagementGeneralprofileItemSuccess, deleteUsermanagementGeneralprofileItemFailure} from '../features/usermanagement'

import {fetchUsermanagementBadgeListStart, fetchUsermanagementBadgeListSuccess, fetchUsermanagementBadgeListFailure} from '../features/usermanagement'
import {fetchUsermanagementBadgeItemStart, fetchUsermanagementBadgeItemSuccess, fetchUsermanagementBadgeItemFailure} from '../features/usermanagement'
import {createUsermanagementBadgeItemStart, createUsermanagementBadgeItemSuccess, createUsermanagementBadgeItemFailure} from '../features/usermanagement'
import {updateUsermanagementBadgeItemStart, updateUsermanagementBadgeItemSuccess, updateUsermanagementBadgeItemFailure} from '../features/usermanagement'
import {deleteUsermanagementBadgeItemStart, deleteUsermanagementBadgeItemSuccess, deleteUsermanagementBadgeItemFailure} from '../features/usermanagement'


// STARRED ACTIONS
export const fetchUsermanagementStarredList = async (dispatch, info, key) => {
    try{
        dispatch(fetchUsermanagementStarredListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/usermanagement/starred/`, { params: info.params, headers: info.headers });
        dispatch(fetchUsermanagementStarredListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchUsermanagementStarredListFailure({key, error: error.message}));
    }
}
export const fetchUsermanagementStarredItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchUsermanagementStarredItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/usermanagement/starred/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchUsermanagementStarredItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchUsermanagementStarredItemFailure({key, error: error.message}));
    }
}
export const createUsermanagementStarredItem = async (dispatch, info, key) => {
    try{
        dispatch(createUsermanagementStarredItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/usermanagement/starred/`, info.payload, { headers: info.headers });
        dispatch(createUsermanagementStarredItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createUsermanagementStarredItemFailure({key, error: error.message}));
    }
}
export const updateUsermanagementStarredItem = async (dispatch, info, key) => {
    try{
        dispatch(updateUsermanagementStarredItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/usermanagement/starred/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateUsermanagementStarredItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateUsermanagementStarredItemFailure({key, error: error.message}));
    }
}
export const deleteUsermanagementStarredItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteUsermanagementStarredItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/usermanagement/starred/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteUsermanagementStarredItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteUsermanagementStarredItemFailure({key, error: error.message}));
    }
}


// COURSEENROLLMENTPROGRESS ACTIONS
export const fetchUsermanagementCourseenrollmentprogressList = async (dispatch, info, key) => {
    try{
        dispatch(fetchUsermanagementCourseenrollmentprogressListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/usermanagement/courseenrollmentprogress/`, { params: info.params, headers: info.headers });
        dispatch(fetchUsermanagementCourseenrollmentprogressListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchUsermanagementCourseenrollmentprogressListFailure({key, error: error.message}));
    }
}
export const fetchUsermanagementCourseenrollmentprogressItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchUsermanagementCourseenrollmentprogressItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/usermanagement/courseenrollmentprogress/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchUsermanagementCourseenrollmentprogressItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchUsermanagementCourseenrollmentprogressItemFailure({key, error: error.message}));
    }
}
export const createUsermanagementCourseenrollmentprogressItem = async (dispatch, info, key) => {
    try{
        dispatch(createUsermanagementCourseenrollmentprogressItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/usermanagement/courseenrollmentprogress/`, info.payload, { headers: info.headers });
        dispatch(createUsermanagementCourseenrollmentprogressItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createUsermanagementCourseenrollmentprogressItemFailure({key, error: error.message}));
    }
}
export const updateUsermanagementCourseenrollmentprogressItem = async (dispatch, info, key) => {
    try{
        dispatch(updateUsermanagementCourseenrollmentprogressItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/usermanagement/courseenrollmentprogress/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateUsermanagementCourseenrollmentprogressItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateUsermanagementCourseenrollmentprogressItemFailure({key, error: error.message}));
    }
}
export const deleteUsermanagementCourseenrollmentprogressItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteUsermanagementCourseenrollmentprogressItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/usermanagement/courseenrollmentprogress/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteUsermanagementCourseenrollmentprogressItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteUsermanagementCourseenrollmentprogressItemFailure({key, error: error.message}));
    }
}


// SERIESINTEREST ACTIONS
export const fetchUsermanagementSeriesinterestList = async (dispatch, info, key) => {
    try{
        dispatch(fetchUsermanagementSeriesinterestListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/usermanagement/seriesinterest/`, { params: info.params, headers: info.headers });
        dispatch(fetchUsermanagementSeriesinterestListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchUsermanagementSeriesinterestListFailure({key, error: error.message}));
    }
}
export const fetchUsermanagementSeriesinterestItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchUsermanagementSeriesinterestItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/usermanagement/seriesinterest/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchUsermanagementSeriesinterestItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchUsermanagementSeriesinterestItemFailure({key, error: error.message}));
    }
}
export const createUsermanagementSeriesinterestItem = async (dispatch, info, key) => {
    try{
        dispatch(createUsermanagementSeriesinterestItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/usermanagement/seriesinterest/`, info.payload, { headers: info.headers });
        dispatch(createUsermanagementSeriesinterestItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createUsermanagementSeriesinterestItemFailure({key, error: error.message}));
    }
}
export const updateUsermanagementSeriesinterestItem = async (dispatch, info, key) => {
    try{
        dispatch(updateUsermanagementSeriesinterestItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/usermanagement/seriesinterest/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateUsermanagementSeriesinterestItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateUsermanagementSeriesinterestItemFailure({key, error: error.message}));
    }
}
export const deleteUsermanagementSeriesinterestItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteUsermanagementSeriesinterestItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/usermanagement/seriesinterest/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteUsermanagementSeriesinterestItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteUsermanagementSeriesinterestItemFailure({key, error: error.message}));
    }
}


// CHANNELMEMBER ACTIONS
export const fetchUsermanagementChannelmemberList = async (dispatch, info, key) => {
    try{
        dispatch(fetchUsermanagementChannelmemberListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/usermanagement/channelmember/`, { params: info.params, headers: info.headers });
        dispatch(fetchUsermanagementChannelmemberListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchUsermanagementChannelmemberListFailure({key, error: error.message}));
    }
}
export const fetchUsermanagementChannelmemberItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchUsermanagementChannelmemberItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/usermanagement/channelmember/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchUsermanagementChannelmemberItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchUsermanagementChannelmemberItemFailure({key, error: error.message}));
    }
}
export const createUsermanagementChannelmemberItem = async (dispatch, info, key) => {
    try{
        dispatch(createUsermanagementChannelmemberItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/usermanagement/channelmember/`, info.payload, { headers: info.headers });
        dispatch(createUsermanagementChannelmemberItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createUsermanagementChannelmemberItemFailure({key, error: error.message}));
    }
}
export const updateUsermanagementChannelmemberItem = async (dispatch, info, key) => {
    try{
        dispatch(updateUsermanagementChannelmemberItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/usermanagement/channelmember/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateUsermanagementChannelmemberItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateUsermanagementChannelmemberItemFailure({key, error: error.message}));
    }
}
export const deleteUsermanagementChannelmemberItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteUsermanagementChannelmemberItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/usermanagement/channelmember/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteUsermanagementChannelmemberItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteUsermanagementChannelmemberItemFailure({key, error: error.message}));
    }
}


// FORUMMEMBER ACTIONS
export const fetchUsermanagementForummemberList = async (dispatch, info, key) => {
    try{
        dispatch(fetchUsermanagementForummemberListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/usermanagement/forummember/`, { params: info.params, headers: info.headers });
        dispatch(fetchUsermanagementForummemberListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchUsermanagementForummemberListFailure({key, error: error.message}));
    }
}
export const fetchUsermanagementForummemberItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchUsermanagementForummemberItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/usermanagement/forummember/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchUsermanagementForummemberItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchUsermanagementForummemberItemFailure({key, error: error.message}));
    }
}
export const createUsermanagementForummemberItem = async (dispatch, info, key) => {
    try{
        dispatch(createUsermanagementForummemberItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/usermanagement/forummember/`, info.payload, { headers: info.headers });
        dispatch(createUsermanagementForummemberItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createUsermanagementForummemberItemFailure({key, error: error.message}));
    }
}
export const updateUsermanagementForummemberItem = async (dispatch, info, key) => {
    try{
        dispatch(updateUsermanagementForummemberItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/usermanagement/forummember/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateUsermanagementForummemberItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateUsermanagementForummemberItemFailure({key, error: error.message}));
    }
}
export const deleteUsermanagementForummemberItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteUsermanagementForummemberItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/usermanagement/forummember/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteUsermanagementForummemberItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteUsermanagementForummemberItemFailure({key, error: error.message}));
    }
}


// MEMBER ACTIONS
export const fetchUsermanagementMemberList = async (dispatch, info, key) => {
    try{
        dispatch(fetchUsermanagementMemberListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/usermanagement/member/`, { params: info.params, headers: info.headers });
        dispatch(fetchUsermanagementMemberListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchUsermanagementMemberListFailure({key, error: error.message}));
    }
}
export const fetchUsermanagementMemberItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchUsermanagementMemberItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/usermanagement/member/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchUsermanagementMemberItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchUsermanagementMemberItemFailure({key, error: error.message}));
    }
}
export const createUsermanagementMemberItem = async (dispatch, info, key) => {
    try{
        dispatch(createUsermanagementMemberItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/usermanagement/member/`, info.payload, { headers: info.headers });
        dispatch(createUsermanagementMemberItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createUsermanagementMemberItemFailure({key, error: error.message}));
    }
}
export const updateUsermanagementMemberItem = async (dispatch, info, key) => {
    try{
        dispatch(updateUsermanagementMemberItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/usermanagement/member/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateUsermanagementMemberItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateUsermanagementMemberItemFailure({key, error: error.message}));
    }
}
export const deleteUsermanagementMemberItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteUsermanagementMemberItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/usermanagement/member/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteUsermanagementMemberItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteUsermanagementMemberItemFailure({key, error: error.message}));
    }
}


// SECURITYQUESTION ACTIONS
export const fetchUsermanagementSecurityquestionList = async (dispatch, info, key) => {
    try{
        dispatch(fetchUsermanagementSecurityquestionListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/usermanagement/securityquestion/`, { params: info.params, headers: info.headers });
        dispatch(fetchUsermanagementSecurityquestionListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchUsermanagementSecurityquestionListFailure({key, error: error.message}));
    }
}
export const fetchUsermanagementSecurityquestionItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchUsermanagementSecurityquestionItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/usermanagement/securityquestion/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchUsermanagementSecurityquestionItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchUsermanagementSecurityquestionItemFailure({key, error: error.message}));
    }
}
export const createUsermanagementSecurityquestionItem = async (dispatch, info, key) => {
    try{
        dispatch(createUsermanagementSecurityquestionItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/usermanagement/securityquestion/`, info.payload, { headers: info.headers });
        dispatch(createUsermanagementSecurityquestionItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createUsermanagementSecurityquestionItemFailure({key, error: error.message}));
    }
}
export const updateUsermanagementSecurityquestionItem = async (dispatch, info, key) => {
    try{
        dispatch(updateUsermanagementSecurityquestionItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/usermanagement/securityquestion/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateUsermanagementSecurityquestionItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateUsermanagementSecurityquestionItemFailure({key, error: error.message}));
    }
}
export const deleteUsermanagementSecurityquestionItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteUsermanagementSecurityquestionItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/usermanagement/securityquestion/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteUsermanagementSecurityquestionItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteUsermanagementSecurityquestionItemFailure({key, error: error.message}));
    }
}


// OTPVERIFICATION ACTIONS
export const fetchUsermanagementOtpverificationList = async (dispatch, info, key) => {
    try{
        dispatch(fetchUsermanagementOtpverificationListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/usermanagement/otpverification/`, { params: info.params, headers: info.headers });
        dispatch(fetchUsermanagementOtpverificationListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchUsermanagementOtpverificationListFailure({key, error: error.message}));
    }
}
export const fetchUsermanagementOtpverificationItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchUsermanagementOtpverificationItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/usermanagement/otpverification/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchUsermanagementOtpverificationItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchUsermanagementOtpverificationItemFailure({key, error: error.message}));
    }
}
export const createUsermanagementOtpverificationItem = async (dispatch, info, key) => {
    try{
        dispatch(createUsermanagementOtpverificationItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/usermanagement/otpverification/`, info.payload, { headers: info.headers });
        dispatch(createUsermanagementOtpverificationItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createUsermanagementOtpverificationItemFailure({key, error: error.message}));
    }
}
export const updateUsermanagementOtpverificationItem = async (dispatch, info, key) => {
    try{
        dispatch(updateUsermanagementOtpverificationItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/usermanagement/otpverification/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateUsermanagementOtpverificationItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateUsermanagementOtpverificationItemFailure({key, error: error.message}));
    }
}
export const deleteUsermanagementOtpverificationItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteUsermanagementOtpverificationItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/usermanagement/otpverification/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteUsermanagementOtpverificationItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteUsermanagementOtpverificationItemFailure({key, error: error.message}));
    }
}


// GENERALPROFILE ACTIONS
export const fetchUsermanagementGeneralprofileList = async (dispatch, info, key) => {
    try{
        dispatch(fetchUsermanagementGeneralprofileListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/usermanagement/generalprofile/`, { params: info.params, headers: info.headers });
        dispatch(fetchUsermanagementGeneralprofileListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchUsermanagementGeneralprofileListFailure({key, error: error.message}));
    }
}
export const fetchUsermanagementGeneralprofileItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchUsermanagementGeneralprofileItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/usermanagement/generalprofile/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchUsermanagementGeneralprofileItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchUsermanagementGeneralprofileItemFailure({key, error: error.message}));
    }
}
export const createUsermanagementGeneralprofileItem = async (dispatch, info, key) => {
    try{
        dispatch(createUsermanagementGeneralprofileItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/usermanagement/generalprofile/`, info.payload, { headers: info.headers });
        dispatch(createUsermanagementGeneralprofileItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createUsermanagementGeneralprofileItemFailure({key, error: error.message}));
    }
}
export const updateUsermanagementGeneralprofileItem = async (dispatch, info, key) => {
    try{
        dispatch(updateUsermanagementGeneralprofileItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/usermanagement/generalprofile/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateUsermanagementGeneralprofileItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateUsermanagementGeneralprofileItemFailure({key, error: error.message}));
    }
}
export const deleteUsermanagementGeneralprofileItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteUsermanagementGeneralprofileItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/usermanagement/generalprofile/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteUsermanagementGeneralprofileItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteUsermanagementGeneralprofileItemFailure({key, error: error.message}));
    }
}


// BADGE ACTIONS
export const fetchUsermanagementBadgeList = async (dispatch, info, key) => {
    try{
        dispatch(fetchUsermanagementBadgeListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/usermanagement/badge/`, { params: info.params, headers: info.headers });
        dispatch(fetchUsermanagementBadgeListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchUsermanagementBadgeListFailure({key, error: error.message}));
    }
}
export const fetchUsermanagementBadgeItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchUsermanagementBadgeItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/usermanagement/badge/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchUsermanagementBadgeItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchUsermanagementBadgeItemFailure({key, error: error.message}));
    }
}
export const createUsermanagementBadgeItem = async (dispatch, info, key) => {
    try{
        dispatch(createUsermanagementBadgeItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/usermanagement/badge/`, info.payload, { headers: info.headers });
        dispatch(createUsermanagementBadgeItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createUsermanagementBadgeItemFailure({key, error: error.message}));
    }
}
export const updateUsermanagementBadgeItem = async (dispatch, info, key) => {
    try{
        dispatch(updateUsermanagementBadgeItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/usermanagement/badge/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateUsermanagementBadgeItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateUsermanagementBadgeItemFailure({key, error: error.message}));
    }
}
export const deleteUsermanagementBadgeItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteUsermanagementBadgeItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/usermanagement/badge/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteUsermanagementBadgeItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteUsermanagementBadgeItemFailure({key, error: error.message}));
    }
}




