import axios from '../utils/axios';

import {fetchAccessCoursematerialListStart, fetchAccessCoursematerialListSuccess, fetchAccessCoursematerialListFailure} from '../features/access'
import {fetchAccessCoursematerialItemStart, fetchAccessCoursematerialItemSuccess, fetchAccessCoursematerialItemFailure} from '../features/access'
import {createAccessCoursematerialItemStart, createAccessCoursematerialItemSuccess, createAccessCoursematerialItemFailure} from '../features/access'
import {updateAccessCoursematerialItemStart, updateAccessCoursematerialItemSuccess, updateAccessCoursematerialItemFailure} from '../features/access'
import {deleteAccessCoursematerialItemStart, deleteAccessCoursematerialItemSuccess, deleteAccessCoursematerialItemFailure} from '../features/access'

import {fetchAccessNewsletterListStart, fetchAccessNewsletterListSuccess, fetchAccessNewsletterListFailure} from '../features/access'
import {fetchAccessNewsletterItemStart, fetchAccessNewsletterItemSuccess, fetchAccessNewsletterItemFailure} from '../features/access'
import {createAccessNewsletterItemStart, createAccessNewsletterItemSuccess, createAccessNewsletterItemFailure} from '../features/access'
import {updateAccessNewsletterItemStart, updateAccessNewsletterItemSuccess, updateAccessNewsletterItemFailure} from '../features/access'
import {deleteAccessNewsletterItemStart, deleteAccessNewsletterItemSuccess, deleteAccessNewsletterItemFailure} from '../features/access'

import {fetchAccessPostorblogListStart, fetchAccessPostorblogListSuccess, fetchAccessPostorblogListFailure} from '../features/access'
import {fetchAccessPostorblogItemStart, fetchAccessPostorblogItemSuccess, fetchAccessPostorblogItemFailure} from '../features/access'
import {createAccessPostorblogItemStart, createAccessPostorblogItemSuccess, createAccessPostorblogItemFailure} from '../features/access'
import {updateAccessPostorblogItemStart, updateAccessPostorblogItemSuccess, updateAccessPostorblogItemFailure} from '../features/access'
import {deleteAccessPostorblogItemStart, deleteAccessPostorblogItemSuccess, deleteAccessPostorblogItemFailure} from '../features/access'

import {fetchAccessQuizListStart, fetchAccessQuizListSuccess, fetchAccessQuizListFailure} from '../features/access'
import {fetchAccessQuizItemStart, fetchAccessQuizItemSuccess, fetchAccessQuizItemFailure} from '../features/access'
import {createAccessQuizItemStart, createAccessQuizItemSuccess, createAccessQuizItemFailure} from '../features/access'
import {updateAccessQuizItemStart, updateAccessQuizItemSuccess, updateAccessQuizItemFailure} from '../features/access'
import {deleteAccessQuizItemStart, deleteAccessQuizItemSuccess, deleteAccessQuizItemFailure} from '../features/access'

import {fetchAccessSeriesListStart, fetchAccessSeriesListSuccess, fetchAccessSeriesListFailure} from '../features/access'
import {fetchAccessSeriesItemStart, fetchAccessSeriesItemSuccess, fetchAccessSeriesItemFailure} from '../features/access'
import {createAccessSeriesItemStart, createAccessSeriesItemSuccess, createAccessSeriesItemFailure} from '../features/access'
import {updateAccessSeriesItemStart, updateAccessSeriesItemSuccess, updateAccessSeriesItemFailure} from '../features/access'
import {deleteAccessSeriesItemStart, deleteAccessSeriesItemSuccess, deleteAccessSeriesItemFailure} from '../features/access'

import {fetchAccessPublisherregistrationListStart, fetchAccessPublisherregistrationListSuccess, fetchAccessPublisherregistrationListFailure} from '../features/access'
import {fetchAccessPublisherregistrationItemStart, fetchAccessPublisherregistrationItemSuccess, fetchAccessPublisherregistrationItemFailure} from '../features/access'
import {createAccessPublisherregistrationItemStart, createAccessPublisherregistrationItemSuccess, createAccessPublisherregistrationItemFailure} from '../features/access'
import {updateAccessPublisherregistrationItemStart, updateAccessPublisherregistrationItemSuccess, updateAccessPublisherregistrationItemFailure} from '../features/access'
import {deleteAccessPublisherregistrationItemStart, deleteAccessPublisherregistrationItemSuccess, deleteAccessPublisherregistrationItemFailure} from '../features/access'


// COURSEMATERIAL ACTIONS
export const fetchAccessCoursematerialList = async (dispatch, info, key) => {
    try{
        dispatch(fetchAccessCoursematerialListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/access/coursematerial/`, { params: info.params, headers: info.headers });
        dispatch(fetchAccessCoursematerialListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchAccessCoursematerialListFailure({key, error: error.message}));
    }
}
export const fetchAccessCoursematerialItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchAccessCoursematerialItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/access/coursematerial/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchAccessCoursematerialItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchAccessCoursematerialItemFailure({key, error: error.message}));
    }
}
export const createAccessCoursematerialItem = async (dispatch, info, key) => {
    try{
        dispatch(createAccessCoursematerialItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/access/coursematerial/`, info.payload, { headers: info.headers });
        dispatch(createAccessCoursematerialItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createAccessCoursematerialItemFailure({key, error: error.message}));
    }
}
export const updateAccessCoursematerialItem = async (dispatch, info, key) => {
    try{
        dispatch(updateAccessCoursematerialItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/access/coursematerial/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateAccessCoursematerialItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateAccessCoursematerialItemFailure({key, error: error.message}));
    }
}
export const deleteAccessCoursematerialItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteAccessCoursematerialItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/access/coursematerial/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteAccessCoursematerialItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteAccessCoursematerialItemFailure({key, error: error.message}));
    }
}


// NEWSLETTER ACTIONS
export const fetchAccessNewsletterList = async (dispatch, info, key) => {
    try{
        dispatch(fetchAccessNewsletterListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/access/newsletter/`, { params: info.params, headers: info.headers });
        dispatch(fetchAccessNewsletterListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchAccessNewsletterListFailure({key, error: error.message}));
    }
}
export const fetchAccessNewsletterItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchAccessNewsletterItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/access/newsletter/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchAccessNewsletterItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchAccessNewsletterItemFailure({key, error: error.message}));
    }
}
export const createAccessNewsletterItem = async (dispatch, info, key) => {
    try{
        dispatch(createAccessNewsletterItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/access/newsletter/`, info.payload, { headers: info.headers });
        dispatch(createAccessNewsletterItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createAccessNewsletterItemFailure({key, error: error.message}));
    }
}
export const updateAccessNewsletterItem = async (dispatch, info, key) => {
    try{
        dispatch(updateAccessNewsletterItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/access/newsletter/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateAccessNewsletterItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateAccessNewsletterItemFailure({key, error: error.message}));
    }
}
export const deleteAccessNewsletterItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteAccessNewsletterItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/access/newsletter/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteAccessNewsletterItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteAccessNewsletterItemFailure({key, error: error.message}));
    }
}


// POSTORBLOG ACTIONS
export const fetchAccessPostorblogList = async (dispatch, info, key) => {
    try{
        dispatch(fetchAccessPostorblogListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/access/postorblog/`, { params: info.params, headers: info.headers });
        dispatch(fetchAccessPostorblogListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchAccessPostorblogListFailure({key, error: error.message}));
    }
}
export const fetchAccessPostorblogItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchAccessPostorblogItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/access/postorblog/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchAccessPostorblogItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchAccessPostorblogItemFailure({key, error: error.message}));
    }
}
export const createAccessPostorblogItem = async (dispatch, info, key) => {
    try{
        dispatch(createAccessPostorblogItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/access/postorblog/`, info.payload, { headers: info.headers });
        dispatch(createAccessPostorblogItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createAccessPostorblogItemFailure({key, error: error.message}));
    }
}
export const updateAccessPostorblogItem = async (dispatch, info, key) => {
    try{
        dispatch(updateAccessPostorblogItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/access/postorblog/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateAccessPostorblogItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateAccessPostorblogItemFailure({key, error: error.message}));
    }
}
export const deleteAccessPostorblogItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteAccessPostorblogItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/access/postorblog/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteAccessPostorblogItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteAccessPostorblogItemFailure({key, error: error.message}));
    }
}


// QUIZ ACTIONS
export const fetchAccessQuizList = async (dispatch, info, key) => {
    try{
        dispatch(fetchAccessQuizListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/access/quiz/`, { params: info.params, headers: info.headers });
        dispatch(fetchAccessQuizListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchAccessQuizListFailure({key, error: error.message}));
    }
}
export const fetchAccessQuizItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchAccessQuizItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/access/quiz/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchAccessQuizItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchAccessQuizItemFailure({key, error: error.message}));
    }
}
export const createAccessQuizItem = async (dispatch, info, key) => {
    try{
        dispatch(createAccessQuizItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/access/quiz/`, info.payload, { headers: info.headers });
        dispatch(createAccessQuizItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createAccessQuizItemFailure({key, error: error.message}));
    }
}
export const updateAccessQuizItem = async (dispatch, info, key) => {
    try{
        dispatch(updateAccessQuizItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/access/quiz/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateAccessQuizItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateAccessQuizItemFailure({key, error: error.message}));
    }
}
export const deleteAccessQuizItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteAccessQuizItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/access/quiz/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteAccessQuizItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteAccessQuizItemFailure({key, error: error.message}));
    }
}


// SERIES ACTIONS
export const fetchAccessSeriesList = async (dispatch, info, key) => {
    try{
        dispatch(fetchAccessSeriesListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/access/series/`, { params: info.params, headers: info.headers });
        dispatch(fetchAccessSeriesListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchAccessSeriesListFailure({key, error: error.message}));
    }
}
export const fetchAccessSeriesItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchAccessSeriesItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/access/series/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchAccessSeriesItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchAccessSeriesItemFailure({key, error: error.message}));
    }
}
export const createAccessSeriesItem = async (dispatch, info, key) => {
    try{
        dispatch(createAccessSeriesItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/access/series/`, info.payload, { headers: info.headers });
        dispatch(createAccessSeriesItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createAccessSeriesItemFailure({key, error: error.message}));
    }
}
export const updateAccessSeriesItem = async (dispatch, info, key) => {
    try{
        dispatch(updateAccessSeriesItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/access/series/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateAccessSeriesItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateAccessSeriesItemFailure({key, error: error.message}));
    }
}
export const deleteAccessSeriesItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteAccessSeriesItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/access/series/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteAccessSeriesItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteAccessSeriesItemFailure({key, error: error.message}));
    }
}


// PUBLISHERREGISTRATION ACTIONS
export const fetchAccessPublisherregistrationList = async (dispatch, info, key) => {
    try{
        dispatch(fetchAccessPublisherregistrationListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/access/publisherregistration/`, { params: info.params, headers: info.headers });
        dispatch(fetchAccessPublisherregistrationListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchAccessPublisherregistrationListFailure({key, error: error.message}));
    }
}
export const fetchAccessPublisherregistrationItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchAccessPublisherregistrationItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/access/publisherregistration/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchAccessPublisherregistrationItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchAccessPublisherregistrationItemFailure({key, error: error.message}));
    }
}
export const createAccessPublisherregistrationItem = async (dispatch, info, key) => {
    try{
        dispatch(createAccessPublisherregistrationItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/access/publisherregistration/`, info.payload, { headers: info.headers });
        dispatch(createAccessPublisherregistrationItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createAccessPublisherregistrationItemFailure({key, error: error.message}));
    }
}
export const updateAccessPublisherregistrationItem = async (dispatch, info, key) => {
    try{
        dispatch(updateAccessPublisherregistrationItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/access/publisherregistration/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateAccessPublisherregistrationItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateAccessPublisherregistrationItemFailure({key, error: error.message}));
    }
}
export const deleteAccessPublisherregistrationItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteAccessPublisherregistrationItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/access/publisherregistration/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteAccessPublisherregistrationItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteAccessPublisherregistrationItemFailure({key, error: error.message}));
    }
}




