import { createSlice } from '@reduxjs/toolkit';

const stateLoadingStart = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: true, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null };
}
const stateError = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: action.payload.error };
}
const stateSuccess = (state, action, key) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null, [key]: action.payload.data };
}

export const slice = createSlice({
  name: 'event',
  initialState: { loading: false },
  reducers: {
    
    // EVENT REDUCER FUNCTION

    // EVENT FETCH LIST
    fetchEventEventListStart: stateLoadingStart,
    fetchEventEventListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `event_list`),
    fetchEventEventListFailure: stateError,

    // EVENT FETCH ITEM
    fetchEventEventItemStart: stateLoadingStart,
    fetchEventEventItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `event_item`),
    fetchEventEventItemFailure: stateError,

    // EVENT CREATE ITEM
    createEventEventItemStart: stateLoadingStart,
    createEventEventItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `event_item`),
    createEventEventItemFailure: stateError,

    // EVENT UPDATE ITEM
    updateEventEventItemStart: stateLoadingStart,
    updateEventEventItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `event_item`),
    updateEventEventItemFailure: stateError,

    // EVENT DELETE ITEM
    deleteEventEventItemStart: stateLoadingStart,
    deleteEventEventItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_event`),
    deleteEventEventItemFailure: stateError,


    // TICKET REDUCER FUNCTION

    // TICKET FETCH LIST
    fetchEventTicketListStart: stateLoadingStart,
    fetchEventTicketListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `ticket_list`),
    fetchEventTicketListFailure: stateError,

    // TICKET FETCH ITEM
    fetchEventTicketItemStart: stateLoadingStart,
    fetchEventTicketItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `ticket_item`),
    fetchEventTicketItemFailure: stateError,

    // TICKET CREATE ITEM
    createEventTicketItemStart: stateLoadingStart,
    createEventTicketItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `ticket_item`),
    createEventTicketItemFailure: stateError,

    // TICKET UPDATE ITEM
    updateEventTicketItemStart: stateLoadingStart,
    updateEventTicketItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `ticket_item`),
    updateEventTicketItemFailure: stateError,

    // TICKET DELETE ITEM
    deleteEventTicketItemStart: stateLoadingStart,
    deleteEventTicketItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_ticket`),
    deleteEventTicketItemFailure: stateError,


    // ENROLLMENTFORM REDUCER FUNCTION

    // ENROLLMENTFORM FETCH LIST
    fetchEventEnrollmentformListStart: stateLoadingStart,
    fetchEventEnrollmentformListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `enrollmentform_list`),
    fetchEventEnrollmentformListFailure: stateError,

    // ENROLLMENTFORM FETCH ITEM
    fetchEventEnrollmentformItemStart: stateLoadingStart,
    fetchEventEnrollmentformItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `enrollmentform_item`),
    fetchEventEnrollmentformItemFailure: stateError,

    // ENROLLMENTFORM CREATE ITEM
    createEventEnrollmentformItemStart: stateLoadingStart,
    createEventEnrollmentformItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `enrollmentform_item`),
    createEventEnrollmentformItemFailure: stateError,

    // ENROLLMENTFORM UPDATE ITEM
    updateEventEnrollmentformItemStart: stateLoadingStart,
    updateEventEnrollmentformItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `enrollmentform_item`),
    updateEventEnrollmentformItemFailure: stateError,

    // ENROLLMENTFORM DELETE ITEM
    deleteEventEnrollmentformItemStart: stateLoadingStart,
    deleteEventEnrollmentformItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_enrollmentform`),
    deleteEventEnrollmentformItemFailure: stateError,


    // ENROLLMENTINFO REDUCER FUNCTION

    // ENROLLMENTINFO FETCH LIST
    fetchEventEnrollmentinfoListStart: stateLoadingStart,
    fetchEventEnrollmentinfoListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `enrollmentinfo_list`),
    fetchEventEnrollmentinfoListFailure: stateError,

    // ENROLLMENTINFO FETCH ITEM
    fetchEventEnrollmentinfoItemStart: stateLoadingStart,
    fetchEventEnrollmentinfoItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `enrollmentinfo_item`),
    fetchEventEnrollmentinfoItemFailure: stateError,

    // ENROLLMENTINFO CREATE ITEM
    createEventEnrollmentinfoItemStart: stateLoadingStart,
    createEventEnrollmentinfoItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `enrollmentinfo_item`),
    createEventEnrollmentinfoItemFailure: stateError,

    // ENROLLMENTINFO UPDATE ITEM
    updateEventEnrollmentinfoItemStart: stateLoadingStart,
    updateEventEnrollmentinfoItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `enrollmentinfo_item`),
    updateEventEnrollmentinfoItemFailure: stateError,

    // ENROLLMENTINFO DELETE ITEM
    deleteEventEnrollmentinfoItemStart: stateLoadingStart,
    deleteEventEnrollmentinfoItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_enrollmentinfo`),
    deleteEventEnrollmentinfoItemFailure: stateError,


    // FORMFIELD REDUCER FUNCTION

    // FORMFIELD FETCH LIST
    fetchEventFormfieldListStart: stateLoadingStart,
    fetchEventFormfieldListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `formfield_list`),
    fetchEventFormfieldListFailure: stateError,

    // FORMFIELD FETCH ITEM
    fetchEventFormfieldItemStart: stateLoadingStart,
    fetchEventFormfieldItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `formfield_item`),
    fetchEventFormfieldItemFailure: stateError,

    // FORMFIELD CREATE ITEM
    createEventFormfieldItemStart: stateLoadingStart,
    createEventFormfieldItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `formfield_item`),
    createEventFormfieldItemFailure: stateError,

    // FORMFIELD UPDATE ITEM
    updateEventFormfieldItemStart: stateLoadingStart,
    updateEventFormfieldItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `formfield_item`),
    updateEventFormfieldItemFailure: stateError,

    // FORMFIELD DELETE ITEM
    deleteEventFormfieldItemStart: stateLoadingStart,
    deleteEventFormfieldItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_formfield`),
    deleteEventFormfieldItemFailure: stateError,


    // COUPON REDUCER FUNCTION

    // COUPON FETCH LIST
    fetchEventCouponListStart: stateLoadingStart,
    fetchEventCouponListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `coupon_list`),
    fetchEventCouponListFailure: stateError,

    // COUPON FETCH ITEM
    fetchEventCouponItemStart: stateLoadingStart,
    fetchEventCouponItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `coupon_item`),
    fetchEventCouponItemFailure: stateError,

    // COUPON CREATE ITEM
    createEventCouponItemStart: stateLoadingStart,
    createEventCouponItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `coupon_item`),
    createEventCouponItemFailure: stateError,

    // COUPON UPDATE ITEM
    updateEventCouponItemStart: stateLoadingStart,
    updateEventCouponItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `coupon_item`),
    updateEventCouponItemFailure: stateError,

    // COUPON DELETE ITEM
    deleteEventCouponItemStart: stateLoadingStart,
    deleteEventCouponItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_coupon`),
    deleteEventCouponItemFailure: stateError,


  },
});

export const {
  fetchEventEventListStart, fetchEventTicketListStart, fetchEventEnrollmentformListStart, fetchEventEnrollmentinfoListStart, fetchEventFormfieldListStart, fetchEventCouponListStart,
  fetchEventEventListSuccess, fetchEventTicketListSuccess, fetchEventEnrollmentformListSuccess, fetchEventEnrollmentinfoListSuccess, fetchEventFormfieldListSuccess, fetchEventCouponListSuccess,
  fetchEventEventListFailure, fetchEventTicketListFailure, fetchEventEnrollmentformListFailure, fetchEventEnrollmentinfoListFailure, fetchEventFormfieldListFailure, fetchEventCouponListFailure,
  fetchEventEventItemStart, fetchEventTicketItemStart, fetchEventEnrollmentformItemStart, fetchEventEnrollmentinfoItemStart, fetchEventFormfieldItemStart, fetchEventCouponItemStart,
  fetchEventEventItemSuccess, fetchEventTicketItemSuccess, fetchEventEnrollmentformItemSuccess, fetchEventEnrollmentinfoItemSuccess, fetchEventFormfieldItemSuccess, fetchEventCouponItemSuccess,
  fetchEventEventItemFailure, fetchEventTicketItemFailure, fetchEventEnrollmentformItemFailure, fetchEventEnrollmentinfoItemFailure, fetchEventFormfieldItemFailure, fetchEventCouponItemFailure,
  createEventEventItemStart, createEventTicketItemStart, createEventEnrollmentformItemStart, createEventEnrollmentinfoItemStart, createEventFormfieldItemStart, createEventCouponItemStart,
  createEventEventItemSuccess, createEventTicketItemSuccess, createEventEnrollmentformItemSuccess, createEventEnrollmentinfoItemSuccess, createEventFormfieldItemSuccess, createEventCouponItemSuccess,
  createEventEventItemFailure, createEventTicketItemFailure, createEventEnrollmentformItemFailure, createEventEnrollmentinfoItemFailure, createEventFormfieldItemFailure, createEventCouponItemFailure,
  updateEventEventItemStart, updateEventTicketItemStart, updateEventEnrollmentformItemStart, updateEventEnrollmentinfoItemStart, updateEventFormfieldItemStart, updateEventCouponItemStart,
  updateEventEventItemSuccess, updateEventTicketItemSuccess, updateEventEnrollmentformItemSuccess, updateEventEnrollmentinfoItemSuccess, updateEventFormfieldItemSuccess, updateEventCouponItemSuccess,
  updateEventEventItemFailure, updateEventTicketItemFailure, updateEventEnrollmentformItemFailure, updateEventEnrollmentinfoItemFailure, updateEventFormfieldItemFailure, updateEventCouponItemFailure,
  deleteEventEventItemStart, deleteEventTicketItemStart, deleteEventEnrollmentformItemStart, deleteEventEnrollmentinfoItemStart, deleteEventFormfieldItemStart, deleteEventCouponItemStart,
  deleteEventEventItemSuccess, deleteEventTicketItemSuccess, deleteEventEnrollmentformItemSuccess, deleteEventEnrollmentinfoItemSuccess, deleteEventFormfieldItemSuccess, deleteEventCouponItemSuccess,
  deleteEventEventItemFailure, deleteEventTicketItemFailure, deleteEventEnrollmentformItemFailure, deleteEventEnrollmentinfoItemFailure, deleteEventFormfieldItemFailure, deleteEventCouponItemFailure
} = slice.actions;

export default slice.reducer;
