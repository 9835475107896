import { createSlice } from '@reduxjs/toolkit';

const stateLoadingStart = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: true, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null };
}
const stateError = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: action.payload.error };
}
const stateSuccess = (state, action, key) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null, [key]: action.payload.data };
}

export const slice = createSlice({
  name: 'quiz',
  initialState: { loading: false },
  reducers: {
    
    // QUIZSET REDUCER FUNCTION

    // QUIZSET FETCH LIST
    fetchQuizQuizsetListStart: stateLoadingStart,
    fetchQuizQuizsetListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `quizset_list`),
    fetchQuizQuizsetListFailure: stateError,

    // QUIZSET FETCH ITEM
    fetchQuizQuizsetItemStart: stateLoadingStart,
    fetchQuizQuizsetItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `quizset_item`),
    fetchQuizQuizsetItemFailure: stateError,

    // QUIZSET CREATE ITEM
    createQuizQuizsetItemStart: stateLoadingStart,
    createQuizQuizsetItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `quizset_item`),
    createQuizQuizsetItemFailure: stateError,

    // QUIZSET UPDATE ITEM
    updateQuizQuizsetItemStart: stateLoadingStart,
    updateQuizQuizsetItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `quizset_item`),
    updateQuizQuizsetItemFailure: stateError,

    // QUIZSET DELETE ITEM
    deleteQuizQuizsetItemStart: stateLoadingStart,
    deleteQuizQuizsetItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_quizset`),
    deleteQuizQuizsetItemFailure: stateError,


    // QUIZITEM REDUCER FUNCTION

    // QUIZITEM FETCH LIST
    fetchQuizQuizitemListStart: stateLoadingStart,
    fetchQuizQuizitemListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `quizitem_list`),
    fetchQuizQuizitemListFailure: stateError,

    // QUIZITEM FETCH ITEM
    fetchQuizQuizitemItemStart: stateLoadingStart,
    fetchQuizQuizitemItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `quizitem_item`),
    fetchQuizQuizitemItemFailure: stateError,

    // QUIZITEM CREATE ITEM
    createQuizQuizitemItemStart: stateLoadingStart,
    createQuizQuizitemItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `quizitem_item`),
    createQuizQuizitemItemFailure: stateError,

    // QUIZITEM UPDATE ITEM
    updateQuizQuizitemItemStart: stateLoadingStart,
    updateQuizQuizitemItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `quizitem_item`),
    updateQuizQuizitemItemFailure: stateError,

    // QUIZITEM DELETE ITEM
    deleteQuizQuizitemItemStart: stateLoadingStart,
    deleteQuizQuizitemItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_quizitem`),
    deleteQuizQuizitemItemFailure: stateError,


    // QUIZRESPONSE REDUCER FUNCTION

    // QUIZRESPONSE FETCH LIST
    fetchQuizQuizresponseListStart: stateLoadingStart,
    fetchQuizQuizresponseListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `quizresponse_list`),
    fetchQuizQuizresponseListFailure: stateError,

    // QUIZRESPONSE FETCH ITEM
    fetchQuizQuizresponseItemStart: stateLoadingStart,
    fetchQuizQuizresponseItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `quizresponse_item`),
    fetchQuizQuizresponseItemFailure: stateError,

    // QUIZRESPONSE CREATE ITEM
    createQuizQuizresponseItemStart: stateLoadingStart,
    createQuizQuizresponseItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `quizresponse_item`),
    createQuizQuizresponseItemFailure: stateError,

    // QUIZRESPONSE UPDATE ITEM
    updateQuizQuizresponseItemStart: stateLoadingStart,
    updateQuizQuizresponseItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `quizresponse_item`),
    updateQuizQuizresponseItemFailure: stateError,

    // QUIZRESPONSE DELETE ITEM
    deleteQuizQuizresponseItemStart: stateLoadingStart,
    deleteQuizQuizresponseItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_quizresponse`),
    deleteQuizQuizresponseItemFailure: stateError,


    // QUIZSUBMISSION REDUCER FUNCTION

    // QUIZSUBMISSION FETCH LIST
    fetchQuizQuizsubmissionListStart: stateLoadingStart,
    fetchQuizQuizsubmissionListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `quizsubmission_list`),
    fetchQuizQuizsubmissionListFailure: stateError,

    // QUIZSUBMISSION FETCH ITEM
    fetchQuizQuizsubmissionItemStart: stateLoadingStart,
    fetchQuizQuizsubmissionItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `quizsubmission_item`),
    fetchQuizQuizsubmissionItemFailure: stateError,

    // QUIZSUBMISSION CREATE ITEM
    createQuizQuizsubmissionItemStart: stateLoadingStart,
    createQuizQuizsubmissionItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `quizsubmission_item`),
    createQuizQuizsubmissionItemFailure: stateError,

    // QUIZSUBMISSION UPDATE ITEM
    updateQuizQuizsubmissionItemStart: stateLoadingStart,
    updateQuizQuizsubmissionItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `quizsubmission_item`),
    updateQuizQuizsubmissionItemFailure: stateError,

    // QUIZSUBMISSION DELETE ITEM
    deleteQuizQuizsubmissionItemStart: stateLoadingStart,
    deleteQuizQuizsubmissionItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_quizsubmission`),
    deleteQuizQuizsubmissionItemFailure: stateError,


    // QUIZCORRECTANSWER REDUCER FUNCTION

    // QUIZCORRECTANSWER FETCH LIST
    fetchQuizQuizcorrectanswerListStart: stateLoadingStart,
    fetchQuizQuizcorrectanswerListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `quizcorrectanswer_list`),
    fetchQuizQuizcorrectanswerListFailure: stateError,

    // QUIZCORRECTANSWER FETCH ITEM
    fetchQuizQuizcorrectanswerItemStart: stateLoadingStart,
    fetchQuizQuizcorrectanswerItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `quizcorrectanswer_item`),
    fetchQuizQuizcorrectanswerItemFailure: stateError,

    // QUIZCORRECTANSWER CREATE ITEM
    createQuizQuizcorrectanswerItemStart: stateLoadingStart,
    createQuizQuizcorrectanswerItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `quizcorrectanswer_item`),
    createQuizQuizcorrectanswerItemFailure: stateError,

    // QUIZCORRECTANSWER UPDATE ITEM
    updateQuizQuizcorrectanswerItemStart: stateLoadingStart,
    updateQuizQuizcorrectanswerItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `quizcorrectanswer_item`),
    updateQuizQuizcorrectanswerItemFailure: stateError,

    // QUIZCORRECTANSWER DELETE ITEM
    deleteQuizQuizcorrectanswerItemStart: stateLoadingStart,
    deleteQuizQuizcorrectanswerItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_quizcorrectanswer`),
    deleteQuizQuizcorrectanswerItemFailure: stateError,


  },
});

export const {
  fetchQuizQuizsetListStart, fetchQuizQuizitemListStart, fetchQuizQuizresponseListStart, fetchQuizQuizsubmissionListStart, fetchQuizQuizcorrectanswerListStart,
  fetchQuizQuizsetListSuccess, fetchQuizQuizitemListSuccess, fetchQuizQuizresponseListSuccess, fetchQuizQuizsubmissionListSuccess, fetchQuizQuizcorrectanswerListSuccess,
  fetchQuizQuizsetListFailure, fetchQuizQuizitemListFailure, fetchQuizQuizresponseListFailure, fetchQuizQuizsubmissionListFailure, fetchQuizQuizcorrectanswerListFailure,
  fetchQuizQuizsetItemStart, fetchQuizQuizitemItemStart, fetchQuizQuizresponseItemStart, fetchQuizQuizsubmissionItemStart, fetchQuizQuizcorrectanswerItemStart,
  fetchQuizQuizsetItemSuccess, fetchQuizQuizitemItemSuccess, fetchQuizQuizresponseItemSuccess, fetchQuizQuizsubmissionItemSuccess, fetchQuizQuizcorrectanswerItemSuccess,
  fetchQuizQuizsetItemFailure, fetchQuizQuizitemItemFailure, fetchQuizQuizresponseItemFailure, fetchQuizQuizsubmissionItemFailure, fetchQuizQuizcorrectanswerItemFailure,
  createQuizQuizsetItemStart, createQuizQuizitemItemStart, createQuizQuizresponseItemStart, createQuizQuizsubmissionItemStart, createQuizQuizcorrectanswerItemStart,
  createQuizQuizsetItemSuccess, createQuizQuizitemItemSuccess, createQuizQuizresponseItemSuccess, createQuizQuizsubmissionItemSuccess, createQuizQuizcorrectanswerItemSuccess,
  createQuizQuizsetItemFailure, createQuizQuizitemItemFailure, createQuizQuizresponseItemFailure, createQuizQuizsubmissionItemFailure, createQuizQuizcorrectanswerItemFailure,
  updateQuizQuizsetItemStart, updateQuizQuizitemItemStart, updateQuizQuizresponseItemStart, updateQuizQuizsubmissionItemStart, updateQuizQuizcorrectanswerItemStart,
  updateQuizQuizsetItemSuccess, updateQuizQuizitemItemSuccess, updateQuizQuizresponseItemSuccess, updateQuizQuizsubmissionItemSuccess, updateQuizQuizcorrectanswerItemSuccess,
  updateQuizQuizsetItemFailure, updateQuizQuizitemItemFailure, updateQuizQuizresponseItemFailure, updateQuizQuizsubmissionItemFailure, updateQuizQuizcorrectanswerItemFailure,
  deleteQuizQuizsetItemStart, deleteQuizQuizitemItemStart, deleteQuizQuizresponseItemStart, deleteQuizQuizsubmissionItemStart, deleteQuizQuizcorrectanswerItemStart,
  deleteQuizQuizsetItemSuccess, deleteQuizQuizitemItemSuccess, deleteQuizQuizresponseItemSuccess, deleteQuizQuizsubmissionItemSuccess, deleteQuizQuizcorrectanswerItemSuccess,
  deleteQuizQuizsetItemFailure, deleteQuizQuizitemItemFailure, deleteQuizQuizresponseItemFailure, deleteQuizQuizsubmissionItemFailure, deleteQuizQuizcorrectanswerItemFailure
} = slice.actions;

export default slice.reducer;
