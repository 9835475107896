import { createSlice } from '@reduxjs/toolkit';

const stateLoadingStart = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: true, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null };
}
const stateError = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: action.payload.error };
}
const stateSuccess = (state, action, key) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null, [key]: action.payload.data };
}

export const slice = createSlice({
  name: 'access',
  initialState: { loading: false },
  reducers: {
    
    // COURSEMATERIAL REDUCER FUNCTION

    // COURSEMATERIAL FETCH LIST
    fetchAccessCoursematerialListStart: stateLoadingStart,
    fetchAccessCoursematerialListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `coursematerial_list`),
    fetchAccessCoursematerialListFailure: stateError,

    // COURSEMATERIAL FETCH ITEM
    fetchAccessCoursematerialItemStart: stateLoadingStart,
    fetchAccessCoursematerialItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `coursematerial_item`),
    fetchAccessCoursematerialItemFailure: stateError,

    // COURSEMATERIAL CREATE ITEM
    createAccessCoursematerialItemStart: stateLoadingStart,
    createAccessCoursematerialItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `coursematerial_item`),
    createAccessCoursematerialItemFailure: stateError,

    // COURSEMATERIAL UPDATE ITEM
    updateAccessCoursematerialItemStart: stateLoadingStart,
    updateAccessCoursematerialItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `coursematerial_item`),
    updateAccessCoursematerialItemFailure: stateError,

    // COURSEMATERIAL DELETE ITEM
    deleteAccessCoursematerialItemStart: stateLoadingStart,
    deleteAccessCoursematerialItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_coursematerial`),
    deleteAccessCoursematerialItemFailure: stateError,


    // NEWSLETTER REDUCER FUNCTION

    // NEWSLETTER FETCH LIST
    fetchAccessNewsletterListStart: stateLoadingStart,
    fetchAccessNewsletterListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `newsletter_list`),
    fetchAccessNewsletterListFailure: stateError,

    // NEWSLETTER FETCH ITEM
    fetchAccessNewsletterItemStart: stateLoadingStart,
    fetchAccessNewsletterItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `newsletter_item`),
    fetchAccessNewsletterItemFailure: stateError,

    // NEWSLETTER CREATE ITEM
    createAccessNewsletterItemStart: stateLoadingStart,
    createAccessNewsletterItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `newsletter_item`),
    createAccessNewsletterItemFailure: stateError,

    // NEWSLETTER UPDATE ITEM
    updateAccessNewsletterItemStart: stateLoadingStart,
    updateAccessNewsletterItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `newsletter_item`),
    updateAccessNewsletterItemFailure: stateError,

    // NEWSLETTER DELETE ITEM
    deleteAccessNewsletterItemStart: stateLoadingStart,
    deleteAccessNewsletterItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_newsletter`),
    deleteAccessNewsletterItemFailure: stateError,


    // POSTORBLOG REDUCER FUNCTION

    // POSTORBLOG FETCH LIST
    fetchAccessPostorblogListStart: stateLoadingStart,
    fetchAccessPostorblogListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `postorblog_list`),
    fetchAccessPostorblogListFailure: stateError,

    // POSTORBLOG FETCH ITEM
    fetchAccessPostorblogItemStart: stateLoadingStart,
    fetchAccessPostorblogItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `postorblog_item`),
    fetchAccessPostorblogItemFailure: stateError,

    // POSTORBLOG CREATE ITEM
    createAccessPostorblogItemStart: stateLoadingStart,
    createAccessPostorblogItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `postorblog_item`),
    createAccessPostorblogItemFailure: stateError,

    // POSTORBLOG UPDATE ITEM
    updateAccessPostorblogItemStart: stateLoadingStart,
    updateAccessPostorblogItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `postorblog_item`),
    updateAccessPostorblogItemFailure: stateError,

    // POSTORBLOG DELETE ITEM
    deleteAccessPostorblogItemStart: stateLoadingStart,
    deleteAccessPostorblogItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_postorblog`),
    deleteAccessPostorblogItemFailure: stateError,


    // QUIZ REDUCER FUNCTION

    // QUIZ FETCH LIST
    fetchAccessQuizListStart: stateLoadingStart,
    fetchAccessQuizListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `quiz_list`),
    fetchAccessQuizListFailure: stateError,

    // QUIZ FETCH ITEM
    fetchAccessQuizItemStart: stateLoadingStart,
    fetchAccessQuizItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `quiz_item`),
    fetchAccessQuizItemFailure: stateError,

    // QUIZ CREATE ITEM
    createAccessQuizItemStart: stateLoadingStart,
    createAccessQuizItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `quiz_item`),
    createAccessQuizItemFailure: stateError,

    // QUIZ UPDATE ITEM
    updateAccessQuizItemStart: stateLoadingStart,
    updateAccessQuizItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `quiz_item`),
    updateAccessQuizItemFailure: stateError,

    // QUIZ DELETE ITEM
    deleteAccessQuizItemStart: stateLoadingStart,
    deleteAccessQuizItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_quiz`),
    deleteAccessQuizItemFailure: stateError,


    // SERIES REDUCER FUNCTION

    // SERIES FETCH LIST
    fetchAccessSeriesListStart: stateLoadingStart,
    fetchAccessSeriesListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `series_list`),
    fetchAccessSeriesListFailure: stateError,

    // SERIES FETCH ITEM
    fetchAccessSeriesItemStart: stateLoadingStart,
    fetchAccessSeriesItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `series_item`),
    fetchAccessSeriesItemFailure: stateError,

    // SERIES CREATE ITEM
    createAccessSeriesItemStart: stateLoadingStart,
    createAccessSeriesItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `series_item`),
    createAccessSeriesItemFailure: stateError,

    // SERIES UPDATE ITEM
    updateAccessSeriesItemStart: stateLoadingStart,
    updateAccessSeriesItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `series_item`),
    updateAccessSeriesItemFailure: stateError,

    // SERIES DELETE ITEM
    deleteAccessSeriesItemStart: stateLoadingStart,
    deleteAccessSeriesItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_series`),
    deleteAccessSeriesItemFailure: stateError,


    // PUBLISHERREGISTRATION REDUCER FUNCTION

    // PUBLISHERREGISTRATION FETCH LIST
    fetchAccessPublisherregistrationListStart: stateLoadingStart,
    fetchAccessPublisherregistrationListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `publisherregistration_list`),
    fetchAccessPublisherregistrationListFailure: stateError,

    // PUBLISHERREGISTRATION FETCH ITEM
    fetchAccessPublisherregistrationItemStart: stateLoadingStart,
    fetchAccessPublisherregistrationItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `publisherregistration_item`),
    fetchAccessPublisherregistrationItemFailure: stateError,

    // PUBLISHERREGISTRATION CREATE ITEM
    createAccessPublisherregistrationItemStart: stateLoadingStart,
    createAccessPublisherregistrationItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `publisherregistration_item`),
    createAccessPublisherregistrationItemFailure: stateError,

    // PUBLISHERREGISTRATION UPDATE ITEM
    updateAccessPublisherregistrationItemStart: stateLoadingStart,
    updateAccessPublisherregistrationItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `publisherregistration_item`),
    updateAccessPublisherregistrationItemFailure: stateError,

    // PUBLISHERREGISTRATION DELETE ITEM
    deleteAccessPublisherregistrationItemStart: stateLoadingStart,
    deleteAccessPublisherregistrationItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_publisherregistration`),
    deleteAccessPublisherregistrationItemFailure: stateError,


  },
});

export const {
  fetchAccessCoursematerialListStart, fetchAccessNewsletterListStart, fetchAccessPostorblogListStart, fetchAccessQuizListStart, fetchAccessSeriesListStart, fetchAccessPublisherregistrationListStart,
  fetchAccessCoursematerialListSuccess, fetchAccessNewsletterListSuccess, fetchAccessPostorblogListSuccess, fetchAccessQuizListSuccess, fetchAccessSeriesListSuccess, fetchAccessPublisherregistrationListSuccess,
  fetchAccessCoursematerialListFailure, fetchAccessNewsletterListFailure, fetchAccessPostorblogListFailure, fetchAccessQuizListFailure, fetchAccessSeriesListFailure, fetchAccessPublisherregistrationListFailure,
  fetchAccessCoursematerialItemStart, fetchAccessNewsletterItemStart, fetchAccessPostorblogItemStart, fetchAccessQuizItemStart, fetchAccessSeriesItemStart, fetchAccessPublisherregistrationItemStart,
  fetchAccessCoursematerialItemSuccess, fetchAccessNewsletterItemSuccess, fetchAccessPostorblogItemSuccess, fetchAccessQuizItemSuccess, fetchAccessSeriesItemSuccess, fetchAccessPublisherregistrationItemSuccess,
  fetchAccessCoursematerialItemFailure, fetchAccessNewsletterItemFailure, fetchAccessPostorblogItemFailure, fetchAccessQuizItemFailure, fetchAccessSeriesItemFailure, fetchAccessPublisherregistrationItemFailure,
  createAccessCoursematerialItemStart, createAccessNewsletterItemStart, createAccessPostorblogItemStart, createAccessQuizItemStart, createAccessSeriesItemStart, createAccessPublisherregistrationItemStart,
  createAccessCoursematerialItemSuccess, createAccessNewsletterItemSuccess, createAccessPostorblogItemSuccess, createAccessQuizItemSuccess, createAccessSeriesItemSuccess, createAccessPublisherregistrationItemSuccess,
  createAccessCoursematerialItemFailure, createAccessNewsletterItemFailure, createAccessPostorblogItemFailure, createAccessQuizItemFailure, createAccessSeriesItemFailure, createAccessPublisherregistrationItemFailure,
  updateAccessCoursematerialItemStart, updateAccessNewsletterItemStart, updateAccessPostorblogItemStart, updateAccessQuizItemStart, updateAccessSeriesItemStart, updateAccessPublisherregistrationItemStart,
  updateAccessCoursematerialItemSuccess, updateAccessNewsletterItemSuccess, updateAccessPostorblogItemSuccess, updateAccessQuizItemSuccess, updateAccessSeriesItemSuccess, updateAccessPublisherregistrationItemSuccess,
  updateAccessCoursematerialItemFailure, updateAccessNewsletterItemFailure, updateAccessPostorblogItemFailure, updateAccessQuizItemFailure, updateAccessSeriesItemFailure, updateAccessPublisherregistrationItemFailure,
  deleteAccessCoursematerialItemStart, deleteAccessNewsletterItemStart, deleteAccessPostorblogItemStart, deleteAccessQuizItemStart, deleteAccessSeriesItemStart, deleteAccessPublisherregistrationItemStart,
  deleteAccessCoursematerialItemSuccess, deleteAccessNewsletterItemSuccess, deleteAccessPostorblogItemSuccess, deleteAccessQuizItemSuccess, deleteAccessSeriesItemSuccess, deleteAccessPublisherregistrationItemSuccess,
  deleteAccessCoursematerialItemFailure, deleteAccessNewsletterItemFailure, deleteAccessPostorblogItemFailure, deleteAccessQuizItemFailure, deleteAccessSeriesItemFailure, deleteAccessPublisherregistrationItemFailure
} = slice.actions;

export default slice.reducer;
