import { createSlice } from '@reduxjs/toolkit';

const stateLoadingStart = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: true, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null };
}
const stateError = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: action.payload.error };
}
const stateSuccess = (state, action, key) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null, [key]: action.payload.data };
}

export const slice = createSlice({
  name: 'usermanagement',
  initialState: { loading: false },
  reducers: {
    
    // STARRED REDUCER FUNCTION

    // STARRED FETCH LIST
    fetchUsermanagementStarredListStart: stateLoadingStart,
    fetchUsermanagementStarredListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `starred_list`),
    fetchUsermanagementStarredListFailure: stateError,

    // STARRED FETCH ITEM
    fetchUsermanagementStarredItemStart: stateLoadingStart,
    fetchUsermanagementStarredItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `starred_item`),
    fetchUsermanagementStarredItemFailure: stateError,

    // STARRED CREATE ITEM
    createUsermanagementStarredItemStart: stateLoadingStart,
    createUsermanagementStarredItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `starred_item`),
    createUsermanagementStarredItemFailure: stateError,

    // STARRED UPDATE ITEM
    updateUsermanagementStarredItemStart: stateLoadingStart,
    updateUsermanagementStarredItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `starred_item`),
    updateUsermanagementStarredItemFailure: stateError,

    // STARRED DELETE ITEM
    deleteUsermanagementStarredItemStart: stateLoadingStart,
    deleteUsermanagementStarredItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_starred`),
    deleteUsermanagementStarredItemFailure: stateError,


    // COURSEENROLLMENTPROGRESS REDUCER FUNCTION

    // COURSEENROLLMENTPROGRESS FETCH LIST
    fetchUsermanagementCourseenrollmentprogressListStart: stateLoadingStart,
    fetchUsermanagementCourseenrollmentprogressListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `courseenrollmentprogress_list`),
    fetchUsermanagementCourseenrollmentprogressListFailure: stateError,

    // COURSEENROLLMENTPROGRESS FETCH ITEM
    fetchUsermanagementCourseenrollmentprogressItemStart: stateLoadingStart,
    fetchUsermanagementCourseenrollmentprogressItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `courseenrollmentprogress_item`),
    fetchUsermanagementCourseenrollmentprogressItemFailure: stateError,

    // COURSEENROLLMENTPROGRESS CREATE ITEM
    createUsermanagementCourseenrollmentprogressItemStart: stateLoadingStart,
    createUsermanagementCourseenrollmentprogressItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `courseenrollmentprogress_item`),
    createUsermanagementCourseenrollmentprogressItemFailure: stateError,

    // COURSEENROLLMENTPROGRESS UPDATE ITEM
    updateUsermanagementCourseenrollmentprogressItemStart: stateLoadingStart,
    updateUsermanagementCourseenrollmentprogressItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `courseenrollmentprogress_item`),
    updateUsermanagementCourseenrollmentprogressItemFailure: stateError,

    // COURSEENROLLMENTPROGRESS DELETE ITEM
    deleteUsermanagementCourseenrollmentprogressItemStart: stateLoadingStart,
    deleteUsermanagementCourseenrollmentprogressItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_courseenrollmentprogress`),
    deleteUsermanagementCourseenrollmentprogressItemFailure: stateError,


    // SERIESINTEREST REDUCER FUNCTION

    // SERIESINTEREST FETCH LIST
    fetchUsermanagementSeriesinterestListStart: stateLoadingStart,
    fetchUsermanagementSeriesinterestListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `seriesinterest_list`),
    fetchUsermanagementSeriesinterestListFailure: stateError,

    // SERIESINTEREST FETCH ITEM
    fetchUsermanagementSeriesinterestItemStart: stateLoadingStart,
    fetchUsermanagementSeriesinterestItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `seriesinterest_item`),
    fetchUsermanagementSeriesinterestItemFailure: stateError,

    // SERIESINTEREST CREATE ITEM
    createUsermanagementSeriesinterestItemStart: stateLoadingStart,
    createUsermanagementSeriesinterestItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `seriesinterest_item`),
    createUsermanagementSeriesinterestItemFailure: stateError,

    // SERIESINTEREST UPDATE ITEM
    updateUsermanagementSeriesinterestItemStart: stateLoadingStart,
    updateUsermanagementSeriesinterestItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `seriesinterest_item`),
    updateUsermanagementSeriesinterestItemFailure: stateError,

    // SERIESINTEREST DELETE ITEM
    deleteUsermanagementSeriesinterestItemStart: stateLoadingStart,
    deleteUsermanagementSeriesinterestItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_seriesinterest`),
    deleteUsermanagementSeriesinterestItemFailure: stateError,


    // CHANNELMEMBER REDUCER FUNCTION

    // CHANNELMEMBER FETCH LIST
    fetchUsermanagementChannelmemberListStart: stateLoadingStart,
    fetchUsermanagementChannelmemberListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `channelmember_list`),
    fetchUsermanagementChannelmemberListFailure: stateError,

    // CHANNELMEMBER FETCH ITEM
    fetchUsermanagementChannelmemberItemStart: stateLoadingStart,
    fetchUsermanagementChannelmemberItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `channelmember_item`),
    fetchUsermanagementChannelmemberItemFailure: stateError,

    // CHANNELMEMBER CREATE ITEM
    createUsermanagementChannelmemberItemStart: stateLoadingStart,
    createUsermanagementChannelmemberItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `channelmember_item`),
    createUsermanagementChannelmemberItemFailure: stateError,

    // CHANNELMEMBER UPDATE ITEM
    updateUsermanagementChannelmemberItemStart: stateLoadingStart,
    updateUsermanagementChannelmemberItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `channelmember_item`),
    updateUsermanagementChannelmemberItemFailure: stateError,

    // CHANNELMEMBER DELETE ITEM
    deleteUsermanagementChannelmemberItemStart: stateLoadingStart,
    deleteUsermanagementChannelmemberItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_channelmember`),
    deleteUsermanagementChannelmemberItemFailure: stateError,


    // FORUMMEMBER REDUCER FUNCTION

    // FORUMMEMBER FETCH LIST
    fetchUsermanagementForummemberListStart: stateLoadingStart,
    fetchUsermanagementForummemberListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `forummember_list`),
    fetchUsermanagementForummemberListFailure: stateError,

    // FORUMMEMBER FETCH ITEM
    fetchUsermanagementForummemberItemStart: stateLoadingStart,
    fetchUsermanagementForummemberItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `forummember_item`),
    fetchUsermanagementForummemberItemFailure: stateError,

    // FORUMMEMBER CREATE ITEM
    createUsermanagementForummemberItemStart: stateLoadingStart,
    createUsermanagementForummemberItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `forummember_item`),
    createUsermanagementForummemberItemFailure: stateError,

    // FORUMMEMBER UPDATE ITEM
    updateUsermanagementForummemberItemStart: stateLoadingStart,
    updateUsermanagementForummemberItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `forummember_item`),
    updateUsermanagementForummemberItemFailure: stateError,

    // FORUMMEMBER DELETE ITEM
    deleteUsermanagementForummemberItemStart: stateLoadingStart,
    deleteUsermanagementForummemberItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_forummember`),
    deleteUsermanagementForummemberItemFailure: stateError,


    // MEMBER REDUCER FUNCTION

    // MEMBER FETCH LIST
    fetchUsermanagementMemberListStart: stateLoadingStart,
    fetchUsermanagementMemberListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `member_list`),
    fetchUsermanagementMemberListFailure: stateError,

    // MEMBER FETCH ITEM
    fetchUsermanagementMemberItemStart: stateLoadingStart,
    fetchUsermanagementMemberItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `member_item`),
    fetchUsermanagementMemberItemFailure: stateError,

    // MEMBER CREATE ITEM
    createUsermanagementMemberItemStart: stateLoadingStart,
    createUsermanagementMemberItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `member_item`),
    createUsermanagementMemberItemFailure: stateError,

    // MEMBER UPDATE ITEM
    updateUsermanagementMemberItemStart: stateLoadingStart,
    updateUsermanagementMemberItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `member_item`),
    updateUsermanagementMemberItemFailure: stateError,

    // MEMBER DELETE ITEM
    deleteUsermanagementMemberItemStart: stateLoadingStart,
    deleteUsermanagementMemberItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_member`),
    deleteUsermanagementMemberItemFailure: stateError,


    // SECURITYQUESTION REDUCER FUNCTION

    // SECURITYQUESTION FETCH LIST
    fetchUsermanagementSecurityquestionListStart: stateLoadingStart,
    fetchUsermanagementSecurityquestionListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `securityquestion_list`),
    fetchUsermanagementSecurityquestionListFailure: stateError,

    // SECURITYQUESTION FETCH ITEM
    fetchUsermanagementSecurityquestionItemStart: stateLoadingStart,
    fetchUsermanagementSecurityquestionItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `securityquestion_item`),
    fetchUsermanagementSecurityquestionItemFailure: stateError,

    // SECURITYQUESTION CREATE ITEM
    createUsermanagementSecurityquestionItemStart: stateLoadingStart,
    createUsermanagementSecurityquestionItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `securityquestion_item`),
    createUsermanagementSecurityquestionItemFailure: stateError,

    // SECURITYQUESTION UPDATE ITEM
    updateUsermanagementSecurityquestionItemStart: stateLoadingStart,
    updateUsermanagementSecurityquestionItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `securityquestion_item`),
    updateUsermanagementSecurityquestionItemFailure: stateError,

    // SECURITYQUESTION DELETE ITEM
    deleteUsermanagementSecurityquestionItemStart: stateLoadingStart,
    deleteUsermanagementSecurityquestionItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_securityquestion`),
    deleteUsermanagementSecurityquestionItemFailure: stateError,


    // OTPVERIFICATION REDUCER FUNCTION

    // OTPVERIFICATION FETCH LIST
    fetchUsermanagementOtpverificationListStart: stateLoadingStart,
    fetchUsermanagementOtpverificationListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `otpverification_list`),
    fetchUsermanagementOtpverificationListFailure: stateError,

    // OTPVERIFICATION FETCH ITEM
    fetchUsermanagementOtpverificationItemStart: stateLoadingStart,
    fetchUsermanagementOtpverificationItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `otpverification_item`),
    fetchUsermanagementOtpverificationItemFailure: stateError,

    // OTPVERIFICATION CREATE ITEM
    createUsermanagementOtpverificationItemStart: stateLoadingStart,
    createUsermanagementOtpverificationItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `otpverification_item`),
    createUsermanagementOtpverificationItemFailure: stateError,

    // OTPVERIFICATION UPDATE ITEM
    updateUsermanagementOtpverificationItemStart: stateLoadingStart,
    updateUsermanagementOtpverificationItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `otpverification_item`),
    updateUsermanagementOtpverificationItemFailure: stateError,

    // OTPVERIFICATION DELETE ITEM
    deleteUsermanagementOtpverificationItemStart: stateLoadingStart,
    deleteUsermanagementOtpverificationItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_otpverification`),
    deleteUsermanagementOtpverificationItemFailure: stateError,


    // GENERALPROFILE REDUCER FUNCTION

    // GENERALPROFILE FETCH LIST
    fetchUsermanagementGeneralprofileListStart: stateLoadingStart,
    fetchUsermanagementGeneralprofileListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `generalprofile_list`),
    fetchUsermanagementGeneralprofileListFailure: stateError,

    // GENERALPROFILE FETCH ITEM
    fetchUsermanagementGeneralprofileItemStart: stateLoadingStart,
    fetchUsermanagementGeneralprofileItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `generalprofile_item`),
    fetchUsermanagementGeneralprofileItemFailure: stateError,

    // GENERALPROFILE CREATE ITEM
    createUsermanagementGeneralprofileItemStart: stateLoadingStart,
    createUsermanagementGeneralprofileItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `generalprofile_item`),
    createUsermanagementGeneralprofileItemFailure: stateError,

    // GENERALPROFILE UPDATE ITEM
    updateUsermanagementGeneralprofileItemStart: stateLoadingStart,
    updateUsermanagementGeneralprofileItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `generalprofile_item`),
    updateUsermanagementGeneralprofileItemFailure: stateError,

    // GENERALPROFILE DELETE ITEM
    deleteUsermanagementGeneralprofileItemStart: stateLoadingStart,
    deleteUsermanagementGeneralprofileItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_generalprofile`),
    deleteUsermanagementGeneralprofileItemFailure: stateError,


    // BADGE REDUCER FUNCTION

    // BADGE FETCH LIST
    fetchUsermanagementBadgeListStart: stateLoadingStart,
    fetchUsermanagementBadgeListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `badge_list`),
    fetchUsermanagementBadgeListFailure: stateError,

    // BADGE FETCH ITEM
    fetchUsermanagementBadgeItemStart: stateLoadingStart,
    fetchUsermanagementBadgeItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `badge_item`),
    fetchUsermanagementBadgeItemFailure: stateError,

    // BADGE CREATE ITEM
    createUsermanagementBadgeItemStart: stateLoadingStart,
    createUsermanagementBadgeItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `badge_item`),
    createUsermanagementBadgeItemFailure: stateError,

    // BADGE UPDATE ITEM
    updateUsermanagementBadgeItemStart: stateLoadingStart,
    updateUsermanagementBadgeItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `badge_item`),
    updateUsermanagementBadgeItemFailure: stateError,

    // BADGE DELETE ITEM
    deleteUsermanagementBadgeItemStart: stateLoadingStart,
    deleteUsermanagementBadgeItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_badge`),
    deleteUsermanagementBadgeItemFailure: stateError,


  },
});

export const {
  fetchUsermanagementStarredListStart, fetchUsermanagementCourseenrollmentprogressListStart, fetchUsermanagementSeriesinterestListStart, fetchUsermanagementChannelmemberListStart, fetchUsermanagementForummemberListStart, fetchUsermanagementMemberListStart, fetchUsermanagementSecurityquestionListStart, fetchUsermanagementOtpverificationListStart, fetchUsermanagementGeneralprofileListStart, fetchUsermanagementBadgeListStart,
  fetchUsermanagementStarredListSuccess, fetchUsermanagementCourseenrollmentprogressListSuccess, fetchUsermanagementSeriesinterestListSuccess, fetchUsermanagementChannelmemberListSuccess, fetchUsermanagementForummemberListSuccess, fetchUsermanagementMemberListSuccess, fetchUsermanagementSecurityquestionListSuccess, fetchUsermanagementOtpverificationListSuccess, fetchUsermanagementGeneralprofileListSuccess, fetchUsermanagementBadgeListSuccess,
  fetchUsermanagementStarredListFailure, fetchUsermanagementCourseenrollmentprogressListFailure, fetchUsermanagementSeriesinterestListFailure, fetchUsermanagementChannelmemberListFailure, fetchUsermanagementForummemberListFailure, fetchUsermanagementMemberListFailure, fetchUsermanagementSecurityquestionListFailure, fetchUsermanagementOtpverificationListFailure, fetchUsermanagementGeneralprofileListFailure, fetchUsermanagementBadgeListFailure,
  fetchUsermanagementStarredItemStart, fetchUsermanagementCourseenrollmentprogressItemStart, fetchUsermanagementSeriesinterestItemStart, fetchUsermanagementChannelmemberItemStart, fetchUsermanagementForummemberItemStart, fetchUsermanagementMemberItemStart, fetchUsermanagementSecurityquestionItemStart, fetchUsermanagementOtpverificationItemStart, fetchUsermanagementGeneralprofileItemStart, fetchUsermanagementBadgeItemStart,
  fetchUsermanagementStarredItemSuccess, fetchUsermanagementCourseenrollmentprogressItemSuccess, fetchUsermanagementSeriesinterestItemSuccess, fetchUsermanagementChannelmemberItemSuccess, fetchUsermanagementForummemberItemSuccess, fetchUsermanagementMemberItemSuccess, fetchUsermanagementSecurityquestionItemSuccess, fetchUsermanagementOtpverificationItemSuccess, fetchUsermanagementGeneralprofileItemSuccess, fetchUsermanagementBadgeItemSuccess,
  fetchUsermanagementStarredItemFailure, fetchUsermanagementCourseenrollmentprogressItemFailure, fetchUsermanagementSeriesinterestItemFailure, fetchUsermanagementChannelmemberItemFailure, fetchUsermanagementForummemberItemFailure, fetchUsermanagementMemberItemFailure, fetchUsermanagementSecurityquestionItemFailure, fetchUsermanagementOtpverificationItemFailure, fetchUsermanagementGeneralprofileItemFailure, fetchUsermanagementBadgeItemFailure,
  createUsermanagementStarredItemStart, createUsermanagementCourseenrollmentprogressItemStart, createUsermanagementSeriesinterestItemStart, createUsermanagementChannelmemberItemStart, createUsermanagementForummemberItemStart, createUsermanagementMemberItemStart, createUsermanagementSecurityquestionItemStart, createUsermanagementOtpverificationItemStart, createUsermanagementGeneralprofileItemStart, createUsermanagementBadgeItemStart,
  createUsermanagementStarredItemSuccess, createUsermanagementCourseenrollmentprogressItemSuccess, createUsermanagementSeriesinterestItemSuccess, createUsermanagementChannelmemberItemSuccess, createUsermanagementForummemberItemSuccess, createUsermanagementMemberItemSuccess, createUsermanagementSecurityquestionItemSuccess, createUsermanagementOtpverificationItemSuccess, createUsermanagementGeneralprofileItemSuccess, createUsermanagementBadgeItemSuccess,
  createUsermanagementStarredItemFailure, createUsermanagementCourseenrollmentprogressItemFailure, createUsermanagementSeriesinterestItemFailure, createUsermanagementChannelmemberItemFailure, createUsermanagementForummemberItemFailure, createUsermanagementMemberItemFailure, createUsermanagementSecurityquestionItemFailure, createUsermanagementOtpverificationItemFailure, createUsermanagementGeneralprofileItemFailure, createUsermanagementBadgeItemFailure,
  updateUsermanagementStarredItemStart, updateUsermanagementCourseenrollmentprogressItemStart, updateUsermanagementSeriesinterestItemStart, updateUsermanagementChannelmemberItemStart, updateUsermanagementForummemberItemStart, updateUsermanagementMemberItemStart, updateUsermanagementSecurityquestionItemStart, updateUsermanagementOtpverificationItemStart, updateUsermanagementGeneralprofileItemStart, updateUsermanagementBadgeItemStart,
  updateUsermanagementStarredItemSuccess, updateUsermanagementCourseenrollmentprogressItemSuccess, updateUsermanagementSeriesinterestItemSuccess, updateUsermanagementChannelmemberItemSuccess, updateUsermanagementForummemberItemSuccess, updateUsermanagementMemberItemSuccess, updateUsermanagementSecurityquestionItemSuccess, updateUsermanagementOtpverificationItemSuccess, updateUsermanagementGeneralprofileItemSuccess, updateUsermanagementBadgeItemSuccess,
  updateUsermanagementStarredItemFailure, updateUsermanagementCourseenrollmentprogressItemFailure, updateUsermanagementSeriesinterestItemFailure, updateUsermanagementChannelmemberItemFailure, updateUsermanagementForummemberItemFailure, updateUsermanagementMemberItemFailure, updateUsermanagementSecurityquestionItemFailure, updateUsermanagementOtpverificationItemFailure, updateUsermanagementGeneralprofileItemFailure, updateUsermanagementBadgeItemFailure,
  deleteUsermanagementStarredItemStart, deleteUsermanagementCourseenrollmentprogressItemStart, deleteUsermanagementSeriesinterestItemStart, deleteUsermanagementChannelmemberItemStart, deleteUsermanagementForummemberItemStart, deleteUsermanagementMemberItemStart, deleteUsermanagementSecurityquestionItemStart, deleteUsermanagementOtpverificationItemStart, deleteUsermanagementGeneralprofileItemStart, deleteUsermanagementBadgeItemStart,
  deleteUsermanagementStarredItemSuccess, deleteUsermanagementCourseenrollmentprogressItemSuccess, deleteUsermanagementSeriesinterestItemSuccess, deleteUsermanagementChannelmemberItemSuccess, deleteUsermanagementForummemberItemSuccess, deleteUsermanagementMemberItemSuccess, deleteUsermanagementSecurityquestionItemSuccess, deleteUsermanagementOtpverificationItemSuccess, deleteUsermanagementGeneralprofileItemSuccess, deleteUsermanagementBadgeItemSuccess,
  deleteUsermanagementStarredItemFailure, deleteUsermanagementCourseenrollmentprogressItemFailure, deleteUsermanagementSeriesinterestItemFailure, deleteUsermanagementChannelmemberItemFailure, deleteUsermanagementForummemberItemFailure, deleteUsermanagementMemberItemFailure, deleteUsermanagementSecurityquestionItemFailure, deleteUsermanagementOtpverificationItemFailure, deleteUsermanagementGeneralprofileItemFailure, deleteUsermanagementBadgeItemFailure
} = slice.actions;

export default slice.reducer;
