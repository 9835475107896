import { configureStore } from '@reduxjs/toolkit';
import accessReducer from '../features/access';
import blogReducer from '../features/blog';
import forumReducer from '../features/forum';
import newsletterReducer from '../features/newsletter';
import gameReducer from '../features/game';
import notificationReducer from '../features/notification';
import seriesReducer from '../features/series';
import websiteinfoReducer from '../features/websiteinfo';
//import calculatorReducer from '../features/calculator';
import openresourceReducer from '../features/openresource';
import showcaseReducer from '../features/showcase';
import communityReducer from '../features/community';
import organizationReducer from '../features/organization';
import driveReducer from '../features/drive';
import coursematerialReducer from '../features/coursematerial';
import monetizationReducer from '../features/monetization';
import quizReducer from '../features/quiz';
import usermanagementReducer from '../features/usermanagement';
import noteReducer from '../features/note';
import researchandarticleReducer from '../features/researchandarticle';
import eventReducer from '../features/event';
import meetReducer from '../features/meet';
import extraReducer from '../features/extra';
import userauthReducer from '../features/userauth';

export default configureStore({
  reducer: {
    access: accessReducer,
    blog: blogReducer,
    forum: forumReducer,
    newsletter: newsletterReducer,
    game: gameReducer,
    notification: notificationReducer,
    series: seriesReducer,
    websiteinfo: websiteinfoReducer,
//    calculator: calculatorReducer,
    openresource: openresourceReducer,
    showcase: showcaseReducer,
    community: communityReducer,
    organization: organizationReducer,
    drive: driveReducer,
    coursematerial: coursematerialReducer,
    monetization: monetizationReducer,
    quiz: quizReducer,
    usermanagement: usermanagementReducer,
    note: noteReducer,
    researchandarticle: researchandarticleReducer,
    event: eventReducer,
    meet: meetReducer,
    extra: extraReducer,
    userauth: userauthReducer,
  },
});
