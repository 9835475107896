import { createSlice } from '@reduxjs/toolkit';

const stateLoadingStart = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: true, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null };
}
const stateError = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: action.payload.error };
}
const stateSuccess = (state, action, key) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null, [key]: action.payload.data };
}

export const slice = createSlice({
  name: 'userauth',
  initialState: { loading: false },
  reducers: {
    
    // SECURITYCHALLENGE REDUCER FUNCTION

    // SECURITYCHALLENGE FETCH LIST
    fetchUserauthSecuritychallengeListStart: stateLoadingStart,
    fetchUserauthSecuritychallengeListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `securitychallenge_list`),
    fetchUserauthSecuritychallengeListFailure: stateError,

    // SECURITYCHALLENGE FETCH ITEM
    fetchUserauthSecuritychallengeItemStart: stateLoadingStart,
    fetchUserauthSecuritychallengeItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `securitychallenge_item`),
    fetchUserauthSecuritychallengeItemFailure: stateError,

    // SECURITYCHALLENGE CREATE ITEM
    createUserauthSecuritychallengeItemStart: stateLoadingStart,
    createUserauthSecuritychallengeItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `securitychallenge_item`),
    createUserauthSecuritychallengeItemFailure: stateError,

    // SECURITYCHALLENGE UPDATE ITEM
    updateUserauthSecuritychallengeItemStart: stateLoadingStart,
    updateUserauthSecuritychallengeItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `securitychallenge_item`),
    updateUserauthSecuritychallengeItemFailure: stateError,

    // SECURITYCHALLENGE DELETE ITEM
    deleteUserauthSecuritychallengeItemStart: stateLoadingStart,
    deleteUserauthSecuritychallengeItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_securitychallenge`),
    deleteUserauthSecuritychallengeItemFailure: stateError,


  },
});

export const {
  fetchUserauthSecuritychallengeListStart,
  fetchUserauthSecuritychallengeListSuccess,
  fetchUserauthSecuritychallengeListFailure,
  fetchUserauthSecuritychallengeItemStart,
  fetchUserauthSecuritychallengeItemSuccess,
  fetchUserauthSecuritychallengeItemFailure,
  createUserauthSecuritychallengeItemStart,
  createUserauthSecuritychallengeItemSuccess,
  createUserauthSecuritychallengeItemFailure,
  updateUserauthSecuritychallengeItemStart,
  updateUserauthSecuritychallengeItemSuccess,
  updateUserauthSecuritychallengeItemFailure,
  deleteUserauthSecuritychallengeItemStart,
  deleteUserauthSecuritychallengeItemSuccess,
  deleteUserauthSecuritychallengeItemFailure
} = slice.actions;

export default slice.reducer;
