import axios from '../utils/axios';

import {fetchEventEventListStart, fetchEventEventListSuccess, fetchEventEventListFailure} from '../features/event'
import {fetchEventEventItemStart, fetchEventEventItemSuccess, fetchEventEventItemFailure} from '../features/event'
import {createEventEventItemStart, createEventEventItemSuccess, createEventEventItemFailure} from '../features/event'
import {updateEventEventItemStart, updateEventEventItemSuccess, updateEventEventItemFailure} from '../features/event'
import {deleteEventEventItemStart, deleteEventEventItemSuccess, deleteEventEventItemFailure} from '../features/event'

import {fetchEventTicketListStart, fetchEventTicketListSuccess, fetchEventTicketListFailure} from '../features/event'
import {fetchEventTicketItemStart, fetchEventTicketItemSuccess, fetchEventTicketItemFailure} from '../features/event'
import {createEventTicketItemStart, createEventTicketItemSuccess, createEventTicketItemFailure} from '../features/event'
import {updateEventTicketItemStart, updateEventTicketItemSuccess, updateEventTicketItemFailure} from '../features/event'
import {deleteEventTicketItemStart, deleteEventTicketItemSuccess, deleteEventTicketItemFailure} from '../features/event'

import {fetchEventEnrollmentformListStart, fetchEventEnrollmentformListSuccess, fetchEventEnrollmentformListFailure} from '../features/event'
import {fetchEventEnrollmentformItemStart, fetchEventEnrollmentformItemSuccess, fetchEventEnrollmentformItemFailure} from '../features/event'
import {createEventEnrollmentformItemStart, createEventEnrollmentformItemSuccess, createEventEnrollmentformItemFailure} from '../features/event'
import {updateEventEnrollmentformItemStart, updateEventEnrollmentformItemSuccess, updateEventEnrollmentformItemFailure} from '../features/event'
import {deleteEventEnrollmentformItemStart, deleteEventEnrollmentformItemSuccess, deleteEventEnrollmentformItemFailure} from '../features/event'

import {fetchEventEnrollmentinfoListStart, fetchEventEnrollmentinfoListSuccess, fetchEventEnrollmentinfoListFailure} from '../features/event'
import {fetchEventEnrollmentinfoItemStart, fetchEventEnrollmentinfoItemSuccess, fetchEventEnrollmentinfoItemFailure} from '../features/event'
import {createEventEnrollmentinfoItemStart, createEventEnrollmentinfoItemSuccess, createEventEnrollmentinfoItemFailure} from '../features/event'
import {updateEventEnrollmentinfoItemStart, updateEventEnrollmentinfoItemSuccess, updateEventEnrollmentinfoItemFailure} from '../features/event'
import {deleteEventEnrollmentinfoItemStart, deleteEventEnrollmentinfoItemSuccess, deleteEventEnrollmentinfoItemFailure} from '../features/event'

import {fetchEventFormfieldListStart, fetchEventFormfieldListSuccess, fetchEventFormfieldListFailure} from '../features/event'
import {fetchEventFormfieldItemStart, fetchEventFormfieldItemSuccess, fetchEventFormfieldItemFailure} from '../features/event'
import {createEventFormfieldItemStart, createEventFormfieldItemSuccess, createEventFormfieldItemFailure} from '../features/event'
import {updateEventFormfieldItemStart, updateEventFormfieldItemSuccess, updateEventFormfieldItemFailure} from '../features/event'
import {deleteEventFormfieldItemStart, deleteEventFormfieldItemSuccess, deleteEventFormfieldItemFailure} from '../features/event'

import {fetchEventCouponListStart, fetchEventCouponListSuccess, fetchEventCouponListFailure} from '../features/event'
import {fetchEventCouponItemStart, fetchEventCouponItemSuccess, fetchEventCouponItemFailure} from '../features/event'
import {createEventCouponItemStart, createEventCouponItemSuccess, createEventCouponItemFailure} from '../features/event'
import {updateEventCouponItemStart, updateEventCouponItemSuccess, updateEventCouponItemFailure} from '../features/event'
import {deleteEventCouponItemStart, deleteEventCouponItemSuccess, deleteEventCouponItemFailure} from '../features/event'


// EVENT ACTIONS
export const fetchEventEventList = async (dispatch, info, key) => {
    try{
        dispatch(fetchEventEventListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/event/event/`, { params: info.params, headers: info.headers });
        dispatch(fetchEventEventListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchEventEventListFailure({key, error: error.message}));
    }
}
export const fetchEventEventItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchEventEventItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/event/event/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchEventEventItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchEventEventItemFailure({key, error: error.message}));
    }
}
export const createEventEventItem = async (dispatch, info, key) => {
    try{
        dispatch(createEventEventItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/event/event/`, info.payload, { headers: info.headers });
        dispatch(createEventEventItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createEventEventItemFailure({key, error: error.message}));
    }
}
export const updateEventEventItem = async (dispatch, info, key) => {
    try{
        dispatch(updateEventEventItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/event/event/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateEventEventItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateEventEventItemFailure({key, error: error.message}));
    }
}
export const deleteEventEventItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteEventEventItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/event/event/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteEventEventItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteEventEventItemFailure({key, error: error.message}));
    }
}


// TICKET ACTIONS
export const fetchEventTicketList = async (dispatch, info, key) => {
    try{
        dispatch(fetchEventTicketListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/event/ticket/`, { params: info.params, headers: info.headers });
        dispatch(fetchEventTicketListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchEventTicketListFailure({key, error: error.message}));
    }
}
export const fetchEventTicketItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchEventTicketItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/event/ticket/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchEventTicketItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchEventTicketItemFailure({key, error: error.message}));
    }
}
export const createEventTicketItem = async (dispatch, info, key) => {
    try{
        dispatch(createEventTicketItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/event/ticket/`, info.payload, { headers: info.headers });
        dispatch(createEventTicketItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createEventTicketItemFailure({key, error: error.message}));
    }
}
export const updateEventTicketItem = async (dispatch, info, key) => {
    try{
        dispatch(updateEventTicketItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/event/ticket/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateEventTicketItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateEventTicketItemFailure({key, error: error.message}));
    }
}
export const deleteEventTicketItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteEventTicketItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/event/ticket/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteEventTicketItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteEventTicketItemFailure({key, error: error.message}));
    }
}


// ENROLLMENTFORM ACTIONS
export const fetchEventEnrollmentformList = async (dispatch, info, key) => {
    try{
        dispatch(fetchEventEnrollmentformListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/event/enrollmentform/`, { params: info.params, headers: info.headers });
        dispatch(fetchEventEnrollmentformListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchEventEnrollmentformListFailure({key, error: error.message}));
    }
}
export const fetchEventEnrollmentformItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchEventEnrollmentformItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/event/enrollmentform/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchEventEnrollmentformItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchEventEnrollmentformItemFailure({key, error: error.message}));
    }
}
export const createEventEnrollmentformItem = async (dispatch, info, key) => {
    try{
        dispatch(createEventEnrollmentformItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/event/enrollmentform/`, info.payload, { headers: info.headers });
        dispatch(createEventEnrollmentformItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createEventEnrollmentformItemFailure({key, error: error.message}));
    }
}
export const updateEventEnrollmentformItem = async (dispatch, info, key) => {
    try{
        dispatch(updateEventEnrollmentformItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/event/enrollmentform/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateEventEnrollmentformItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateEventEnrollmentformItemFailure({key, error: error.message}));
    }
}
export const deleteEventEnrollmentformItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteEventEnrollmentformItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/event/enrollmentform/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteEventEnrollmentformItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteEventEnrollmentformItemFailure({key, error: error.message}));
    }
}


// ENROLLMENTINFO ACTIONS
export const fetchEventEnrollmentinfoList = async (dispatch, info, key) => {
    try{
        dispatch(fetchEventEnrollmentinfoListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/event/enrollmentinfo/`, { params: info.params, headers: info.headers });
        dispatch(fetchEventEnrollmentinfoListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchEventEnrollmentinfoListFailure({key, error: error.message}));
    }
}
export const fetchEventEnrollmentinfoItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchEventEnrollmentinfoItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/event/enrollmentinfo/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchEventEnrollmentinfoItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchEventEnrollmentinfoItemFailure({key, error: error.message}));
    }
}
export const createEventEnrollmentinfoItem = async (dispatch, info, key) => {
    try{
        dispatch(createEventEnrollmentinfoItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/event/enrollmentinfo/`, info.payload, { headers: info.headers });
        dispatch(createEventEnrollmentinfoItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createEventEnrollmentinfoItemFailure({key, error: error.message}));
    }
}
export const updateEventEnrollmentinfoItem = async (dispatch, info, key) => {
    try{
        dispatch(updateEventEnrollmentinfoItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/event/enrollmentinfo/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateEventEnrollmentinfoItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateEventEnrollmentinfoItemFailure({key, error: error.message}));
    }
}
export const deleteEventEnrollmentinfoItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteEventEnrollmentinfoItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/event/enrollmentinfo/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteEventEnrollmentinfoItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteEventEnrollmentinfoItemFailure({key, error: error.message}));
    }
}


// FORMFIELD ACTIONS
export const fetchEventFormfieldList = async (dispatch, info, key) => {
    try{
        dispatch(fetchEventFormfieldListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/event/formfield/`, { params: info.params, headers: info.headers });
        dispatch(fetchEventFormfieldListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchEventFormfieldListFailure({key, error: error.message}));
    }
}
export const fetchEventFormfieldItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchEventFormfieldItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/event/formfield/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchEventFormfieldItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchEventFormfieldItemFailure({key, error: error.message}));
    }
}
export const createEventFormfieldItem = async (dispatch, info, key) => {
    try{
        dispatch(createEventFormfieldItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/event/formfield/`, info.payload, { headers: info.headers });
        dispatch(createEventFormfieldItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createEventFormfieldItemFailure({key, error: error.message}));
    }
}
export const updateEventFormfieldItem = async (dispatch, info, key) => {
    try{
        dispatch(updateEventFormfieldItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/event/formfield/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateEventFormfieldItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateEventFormfieldItemFailure({key, error: error.message}));
    }
}
export const deleteEventFormfieldItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteEventFormfieldItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/event/formfield/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteEventFormfieldItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteEventFormfieldItemFailure({key, error: error.message}));
    }
}


// COUPON ACTIONS
export const fetchEventCouponList = async (dispatch, info, key) => {
    try{
        dispatch(fetchEventCouponListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/event/coupon/`, { params: info.params, headers: info.headers });
        dispatch(fetchEventCouponListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchEventCouponListFailure({key, error: error.message}));
    }
}
export const fetchEventCouponItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchEventCouponItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/event/coupon/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchEventCouponItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchEventCouponItemFailure({key, error: error.message}));
    }
}
export const createEventCouponItem = async (dispatch, info, key) => {
    try{
        dispatch(createEventCouponItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/event/coupon/`, info.payload, { headers: info.headers });
        dispatch(createEventCouponItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createEventCouponItemFailure({key, error: error.message}));
    }
}
export const updateEventCouponItem = async (dispatch, info, key) => {
    try{
        dispatch(updateEventCouponItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/event/coupon/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateEventCouponItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateEventCouponItemFailure({key, error: error.message}));
    }
}
export const deleteEventCouponItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteEventCouponItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/event/coupon/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteEventCouponItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteEventCouponItemFailure({key, error: error.message}));
    }
}




