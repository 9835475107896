import { fetchAccessCoursematerialList, fetchAccessCoursematerialItem, createAccessCoursematerialItem, updateAccessCoursematerialItem, deleteAccessCoursematerialItem, fetchAccessNewsletterList, fetchAccessNewsletterItem, createAccessNewsletterItem, updateAccessNewsletterItem, deleteAccessNewsletterItem, fetchAccessPostorblogList, fetchAccessPostorblogItem, createAccessPostorblogItem, updateAccessPostorblogItem, deleteAccessPostorblogItem, fetchAccessQuizList, fetchAccessQuizItem, createAccessQuizItem, updateAccessQuizItem, deleteAccessQuizItem, fetchAccessSeriesList, fetchAccessSeriesItem, createAccessSeriesItem, updateAccessSeriesItem, deleteAccessSeriesItem, fetchAccessPublisherregistrationList, fetchAccessPublisherregistrationItem, createAccessPublisherregistrationItem, updateAccessPublisherregistrationItem, deleteAccessPublisherregistrationItem } from '../actions/access' 
import { fetchBlogAvailabletagList, fetchBlogAvailabletagItem, createBlogAvailabletagItem, updateBlogAvailabletagItem, deleteBlogAvailabletagItem, fetchBlogPostList, fetchBlogPostItem, createBlogPostItem, updateBlogPostItem, deleteBlogPostItem } from '../actions/blog' 
import { fetchForumPostList, fetchForumPostItem, createForumPostItem, updateForumPostItem, deleteForumPostItem } from '../actions/forum' 
import { fetchNewsletterVisitorList, fetchNewsletterVisitorItem, createNewsletterVisitorItem, updateNewsletterVisitorItem, deleteNewsletterVisitorItem, fetchNewsletterMailList, fetchNewsletterMailItem, createNewsletterMailItem, updateNewsletterMailItem, deleteNewsletterMailItem } from '../actions/newsletter' 
import { fetchGameResourceList, fetchGameResourceItem, createGameResourceItem, updateGameResourceItem, deleteGameResourceItem } from '../actions/game' 
import { fetchNotificationNotificationList, fetchNotificationNotificationItem, createNotificationNotificationItem, updateNotificationNotificationItem, deleteNotificationNotificationItem } from '../actions/notification' 
import { fetchSeriesChannelList, fetchSeriesChannelItem, createSeriesChannelItem, updateSeriesChannelItem, deleteSeriesChannelItem, fetchSeriesContentList, fetchSeriesContentItem, createSeriesContentItem, updateSeriesContentItem, deleteSeriesContentItem } from '../actions/series' 
import { fetchWebsiteinfoSiteList, fetchWebsiteinfoSiteItem, createWebsiteinfoSiteItem, updateWebsiteinfoSiteItem, deleteWebsiteinfoSiteItem, fetchWebsiteinfoSitecontentList, fetchWebsiteinfoSitecontentItem, createWebsiteinfoSitecontentItem, updateWebsiteinfoSitecontentItem, deleteWebsiteinfoSitecontentItem } from '../actions/websiteinfo' 
import { fetchOpenresourceResourceList, fetchOpenresourceResourceItem, createOpenresourceResourceItem, updateOpenresourceResourceItem, deleteOpenresourceResourceItem } from '../actions/openresource' 
import { fetchShowcaseResourceList, fetchShowcaseResourceItem, createShowcaseResourceItem, updateShowcaseResourceItem, deleteShowcaseResourceItem } from '../actions/showcase' 
import { fetchCommunityFollowList, fetchCommunityFollowItem, createCommunityFollowItem, updateCommunityFollowItem, deleteCommunityFollowItem, fetchCommunityGeneralpostList, fetchCommunityGeneralpostItem, createCommunityGeneralpostItem, updateCommunityGeneralpostItem, deleteCommunityGeneralpostItem, fetchCommunityPostList, fetchCommunityPostItem, createCommunityPostItem, updateCommunityPostItem, deleteCommunityPostItem, fetchCommunityCommentList, fetchCommunityCommentItem, createCommunityCommentItem, updateCommunityCommentItem, deleteCommunityCommentItem, fetchCommunityThreadList, fetchCommunityThreadItem, createCommunityThreadItem, updateCommunityThreadItem, deleteCommunityThreadItem, fetchCommunityLikeList, fetchCommunityLikeItem, createCommunityLikeItem, updateCommunityLikeItem, deleteCommunityLikeItem } from '../actions/community' 
import { fetchOrganizationOrganizationList, fetchOrganizationOrganizationItem, createOrganizationOrganizationItem, updateOrganizationOrganizationItem, deleteOrganizationOrganizationItem } from '../actions/organization' 
import { fetchDriveAlloweduserspaceList, fetchDriveAlloweduserspaceItem, createDriveAlloweduserspaceItem, updateDriveAlloweduserspaceItem, deleteDriveAlloweduserspaceItem, fetchDriveFolderList, fetchDriveFolderItem, createDriveFolderItem, updateDriveFolderItem, deleteDriveFolderItem, fetchDriveFileList, fetchDriveFileItem, createDriveFileItem, updateDriveFileItem, deleteDriveFileItem, fetchDriveSharelinkList, fetchDriveSharelinkItem, createDriveSharelinkItem, updateDriveSharelinkItem, deleteDriveSharelinkItem, fetchDriveMessageList, fetchDriveMessageItem, createDriveMessageItem, updateDriveMessageItem, deleteDriveMessageItem } from '../actions/drive' 
import { fetchCoursematerialTopicList, fetchCoursematerialTopicItem, createCoursematerialTopicItem, updateCoursematerialTopicItem, deleteCoursematerialTopicItem, fetchCoursematerialSubjectList, fetchCoursematerialSubjectItem, createCoursematerialSubjectItem, updateCoursematerialSubjectItem, deleteCoursematerialSubjectItem, fetchCoursematerialCourseList, fetchCoursematerialCourseItem, createCoursematerialCourseItem, updateCoursematerialCourseItem, deleteCoursematerialCourseItem, fetchCoursematerialCourseintroList, fetchCoursematerialCourseintroItem, createCoursematerialCourseintroItem, updateCoursematerialCourseintroItem, deleteCoursematerialCourseintroItem, fetchCoursematerialCourselabelList, fetchCoursematerialCourselabelItem, createCoursematerialCourselabelItem, updateCoursematerialCourselabelItem, deleteCoursematerialCourselabelItem, fetchCoursematerialModuleList, fetchCoursematerialModuleItem, createCoursematerialModuleItem, updateCoursematerialModuleItem, deleteCoursematerialModuleItem, fetchCoursematerialResourceList, fetchCoursematerialResourceItem, createCoursematerialResourceItem, updateCoursematerialResourceItem, deleteCoursematerialResourceItem, fetchCoursematerialLessonList, fetchCoursematerialLessonItem, createCoursematerialLessonItem, updateCoursematerialLessonItem, deleteCoursematerialLessonItem, fetchCoursematerialAssignmentList, fetchCoursematerialAssignmentItem, createCoursematerialAssignmentItem, updateCoursematerialAssignmentItem, deleteCoursematerialAssignmentItem, fetchCoursematerialSubmissionList, fetchCoursematerialSubmissionItem, createCoursematerialSubmissionItem, updateCoursematerialSubmissionItem, deleteCoursematerialSubmissionItem, fetchCoursematerialInsightsList, fetchCoursematerialInsightsItem, createCoursematerialInsightsItem, updateCoursematerialInsightsItem, deleteCoursematerialInsightsItem } from '../actions/coursematerial' 
import { fetchMonetizationSubscriptionplanList, fetchMonetizationSubscriptionplanItem, createMonetizationSubscriptionplanItem, updateMonetizationSubscriptionplanItem, deleteMonetizationSubscriptionplanItem, fetchMonetizationGroupmembershipList, fetchMonetizationGroupmembershipItem, createMonetizationGroupmembershipItem, updateMonetizationGroupmembershipItem, deleteMonetizationGroupmembershipItem, fetchMonetizationPurchasedcourseList, fetchMonetizationPurchasedcourseItem, createMonetizationPurchasedcourseItem, updateMonetizationPurchasedcourseItem, deleteMonetizationPurchasedcourseItem, fetchMonetizationNewslettersubscriptionList, fetchMonetizationNewslettersubscriptionItem, createMonetizationNewslettersubscriptionItem, updateMonetizationNewslettersubscriptionItem, deleteMonetizationNewslettersubscriptionItem, fetchMonetizationSeriessubscriptionList, fetchMonetizationSeriessubscriptionItem, createMonetizationSeriessubscriptionItem, updateMonetizationSeriessubscriptionItem, deleteMonetizationSeriessubscriptionItem, fetchMonetizationForumsubscriptionList, fetchMonetizationForumsubscriptionItem, createMonetizationForumsubscriptionItem, updateMonetizationForumsubscriptionItem, deleteMonetizationForumsubscriptionItem, fetchMonetizationEventbookingList, fetchMonetizationEventbookingItem, createMonetizationEventbookingItem, updateMonetizationEventbookingItem, deleteMonetizationEventbookingItem } from '../actions/monetization' 
import { fetchQuizQuizsetList, fetchQuizQuizsetItem, createQuizQuizsetItem, updateQuizQuizsetItem, deleteQuizQuizsetItem, fetchQuizQuizitemList, fetchQuizQuizitemItem, createQuizQuizitemItem, updateQuizQuizitemItem, deleteQuizQuizitemItem, fetchQuizQuizresponseList, fetchQuizQuizresponseItem, createQuizQuizresponseItem, updateQuizQuizresponseItem, deleteQuizQuizresponseItem, fetchQuizQuizsubmissionList, fetchQuizQuizsubmissionItem, createQuizQuizsubmissionItem, updateQuizQuizsubmissionItem, deleteQuizQuizsubmissionItem, fetchQuizQuizcorrectanswerList, fetchQuizQuizcorrectanswerItem, createQuizQuizcorrectanswerItem, updateQuizQuizcorrectanswerItem, deleteQuizQuizcorrectanswerItem } from '../actions/quiz' 
import { fetchUsermanagementStarredList, fetchUsermanagementStarredItem, createUsermanagementStarredItem, updateUsermanagementStarredItem, deleteUsermanagementStarredItem, fetchUsermanagementCourseenrollmentprogressList, fetchUsermanagementCourseenrollmentprogressItem, createUsermanagementCourseenrollmentprogressItem, updateUsermanagementCourseenrollmentprogressItem, deleteUsermanagementCourseenrollmentprogressItem, fetchUsermanagementSeriesinterestList, fetchUsermanagementSeriesinterestItem, createUsermanagementSeriesinterestItem, updateUsermanagementSeriesinterestItem, deleteUsermanagementSeriesinterestItem, fetchUsermanagementChannelmemberList, fetchUsermanagementChannelmemberItem, createUsermanagementChannelmemberItem, updateUsermanagementChannelmemberItem, deleteUsermanagementChannelmemberItem, fetchUsermanagementForummemberList, fetchUsermanagementForummemberItem, createUsermanagementForummemberItem, updateUsermanagementForummemberItem, deleteUsermanagementForummemberItem, fetchUsermanagementMemberList, fetchUsermanagementMemberItem, createUsermanagementMemberItem, updateUsermanagementMemberItem, deleteUsermanagementMemberItem, fetchUsermanagementSecurityquestionList, fetchUsermanagementSecurityquestionItem, createUsermanagementSecurityquestionItem, updateUsermanagementSecurityquestionItem, deleteUsermanagementSecurityquestionItem, fetchUsermanagementOtpverificationList, fetchUsermanagementOtpverificationItem, createUsermanagementOtpverificationItem, updateUsermanagementOtpverificationItem, deleteUsermanagementOtpverificationItem, fetchUsermanagementGeneralprofileList, fetchUsermanagementGeneralprofileItem, createUsermanagementGeneralprofileItem, updateUsermanagementGeneralprofileItem, deleteUsermanagementGeneralprofileItem, fetchUsermanagementBadgeList, fetchUsermanagementBadgeItem, createUsermanagementBadgeItem, updateUsermanagementBadgeItem, deleteUsermanagementBadgeItem } from '../actions/usermanagement' 
import { fetchNoteNoteList, fetchNoteNoteItem, createNoteNoteItem, updateNoteNoteItem, deleteNoteNoteItem } from '../actions/note' 
import { fetchResearchandarticleCategoryList, fetchResearchandarticleCategoryItem, createResearchandarticleCategoryItem, updateResearchandarticleCategoryItem, deleteResearchandarticleCategoryItem, fetchResearchandarticleResearchpaperList, fetchResearchandarticleResearchpaperItem, createResearchandarticleResearchpaperItem, updateResearchandarticleResearchpaperItem, deleteResearchandarticleResearchpaperItem, fetchResearchandarticleArticleList, fetchResearchandarticleArticleItem, createResearchandarticleArticleItem, updateResearchandarticleArticleItem, deleteResearchandarticleArticleItem, fetchResearchandarticleCommentList, fetchResearchandarticleCommentItem, createResearchandarticleCommentItem, updateResearchandarticleCommentItem, deleteResearchandarticleCommentItem } from '../actions/researchandarticle' 
import { fetchEventEventList, fetchEventEventItem, createEventEventItem, updateEventEventItem, deleteEventEventItem, fetchEventTicketList, fetchEventTicketItem, createEventTicketItem, updateEventTicketItem, deleteEventTicketItem, fetchEventEnrollmentformList, fetchEventEnrollmentformItem, createEventEnrollmentformItem, updateEventEnrollmentformItem, deleteEventEnrollmentformItem, fetchEventEnrollmentinfoList, fetchEventEnrollmentinfoItem, createEventEnrollmentinfoItem, updateEventEnrollmentinfoItem, deleteEventEnrollmentinfoItem, fetchEventFormfieldList, fetchEventFormfieldItem, createEventFormfieldItem, updateEventFormfieldItem, deleteEventFormfieldItem, fetchEventCouponList, fetchEventCouponItem, createEventCouponItem, updateEventCouponItem, deleteEventCouponItem } from '../actions/event' 
import { fetchMeetConferenceList, fetchMeetConferenceItem, createMeetConferenceItem, updateMeetConferenceItem, deleteMeetConferenceItem, fetchMeetRoomList, fetchMeetRoomItem, createMeetRoomItem, updateMeetRoomItem, deleteMeetRoomItem, fetchMeetConferenceuserList, fetchMeetConferenceuserItem, createMeetConferenceuserItem, updateMeetConferenceuserItem, deleteMeetConferenceuserItem } from '../actions/meet' 
import { fetchExtraExclusiveaccesscontactsList, fetchExtraExclusiveaccesscontactsItem, createExtraExclusiveaccesscontactsItem, updateExtraExclusiveaccesscontactsItem, deleteExtraExclusiveaccesscontactsItem } from '../actions/extra' 
import { fetchUserauthSecuritychallengeList, fetchUserauthSecuritychallengeItem, createUserauthSecuritychallengeItem, updateUserauthSecuritychallengeItem, deleteUserauthSecuritychallengeItem } from '../actions/userauth' 


export const func_map = {

    'fetchAccessCoursematerialList': fetchAccessCoursematerialList,
    'fetchAccessCoursematerialItem': fetchAccessCoursematerialItem,
    'createAccessCoursematerialItem': createAccessCoursematerialItem,
    'updateAccessCoursematerialItem': updateAccessCoursematerialItem,
    'deleteAccessCoursematerialItem': deleteAccessCoursematerialItem,

    'fetchAccessNewsletterList': fetchAccessNewsletterList,
    'fetchAccessNewsletterItem': fetchAccessNewsletterItem,
    'createAccessNewsletterItem': createAccessNewsletterItem,
    'updateAccessNewsletterItem': updateAccessNewsletterItem,
    'deleteAccessNewsletterItem': deleteAccessNewsletterItem,

    'fetchAccessPostorblogList': fetchAccessPostorblogList,
    'fetchAccessPostorblogItem': fetchAccessPostorblogItem,
    'createAccessPostorblogItem': createAccessPostorblogItem,
    'updateAccessPostorblogItem': updateAccessPostorblogItem,
    'deleteAccessPostorblogItem': deleteAccessPostorblogItem,

    'fetchAccessQuizList': fetchAccessQuizList,
    'fetchAccessQuizItem': fetchAccessQuizItem,
    'createAccessQuizItem': createAccessQuizItem,
    'updateAccessQuizItem': updateAccessQuizItem,
    'deleteAccessQuizItem': deleteAccessQuizItem,

    'fetchAccessSeriesList': fetchAccessSeriesList,
    'fetchAccessSeriesItem': fetchAccessSeriesItem,
    'createAccessSeriesItem': createAccessSeriesItem,
    'updateAccessSeriesItem': updateAccessSeriesItem,
    'deleteAccessSeriesItem': deleteAccessSeriesItem,

    'fetchAccessPublisherregistrationList': fetchAccessPublisherregistrationList,
    'fetchAccessPublisherregistrationItem': fetchAccessPublisherregistrationItem,
    'createAccessPublisherregistrationItem': createAccessPublisherregistrationItem,
    'updateAccessPublisherregistrationItem': updateAccessPublisherregistrationItem,
    'deleteAccessPublisherregistrationItem': deleteAccessPublisherregistrationItem,

    'fetchBlogAvailabletagList': fetchBlogAvailabletagList,
    'fetchBlogAvailabletagItem': fetchBlogAvailabletagItem,
    'createBlogAvailabletagItem': createBlogAvailabletagItem,
    'updateBlogAvailabletagItem': updateBlogAvailabletagItem,
    'deleteBlogAvailabletagItem': deleteBlogAvailabletagItem,

    'fetchBlogPostList': fetchBlogPostList,
    'fetchBlogPostItem': fetchBlogPostItem,
    'createBlogPostItem': createBlogPostItem,
    'updateBlogPostItem': updateBlogPostItem,
    'deleteBlogPostItem': deleteBlogPostItem,

    'fetchForumPostList': fetchForumPostList,
    'fetchForumPostItem': fetchForumPostItem,
    'createForumPostItem': createForumPostItem,
    'updateForumPostItem': updateForumPostItem,
    'deleteForumPostItem': deleteForumPostItem,

    'fetchNewsletterVisitorList': fetchNewsletterVisitorList,
    'fetchNewsletterVisitorItem': fetchNewsletterVisitorItem,
    'createNewsletterVisitorItem': createNewsletterVisitorItem,
    'updateNewsletterVisitorItem': updateNewsletterVisitorItem,
    'deleteNewsletterVisitorItem': deleteNewsletterVisitorItem,

    'fetchNewsletterMailList': fetchNewsletterMailList,
    'fetchNewsletterMailItem': fetchNewsletterMailItem,
    'createNewsletterMailItem': createNewsletterMailItem,
    'updateNewsletterMailItem': updateNewsletterMailItem,
    'deleteNewsletterMailItem': deleteNewsletterMailItem,

    'fetchGameResourceList': fetchGameResourceList,
    'fetchGameResourceItem': fetchGameResourceItem,
    'createGameResourceItem': createGameResourceItem,
    'updateGameResourceItem': updateGameResourceItem,
    'deleteGameResourceItem': deleteGameResourceItem,

    'fetchNotificationNotificationList': fetchNotificationNotificationList,
    'fetchNotificationNotificationItem': fetchNotificationNotificationItem,
    'createNotificationNotificationItem': createNotificationNotificationItem,
    'updateNotificationNotificationItem': updateNotificationNotificationItem,
    'deleteNotificationNotificationItem': deleteNotificationNotificationItem,

    'fetchSeriesChannelList': fetchSeriesChannelList,
    'fetchSeriesChannelItem': fetchSeriesChannelItem,
    'createSeriesChannelItem': createSeriesChannelItem,
    'updateSeriesChannelItem': updateSeriesChannelItem,
    'deleteSeriesChannelItem': deleteSeriesChannelItem,

    'fetchSeriesContentList': fetchSeriesContentList,
    'fetchSeriesContentItem': fetchSeriesContentItem,
    'createSeriesContentItem': createSeriesContentItem,
    'updateSeriesContentItem': updateSeriesContentItem,
    'deleteSeriesContentItem': deleteSeriesContentItem,

    'fetchWebsiteinfoSiteList': fetchWebsiteinfoSiteList,
    'fetchWebsiteinfoSiteItem': fetchWebsiteinfoSiteItem,
    'createWebsiteinfoSiteItem': createWebsiteinfoSiteItem,
    'updateWebsiteinfoSiteItem': updateWebsiteinfoSiteItem,
    'deleteWebsiteinfoSiteItem': deleteWebsiteinfoSiteItem,

    'fetchWebsiteinfoSitecontentList': fetchWebsiteinfoSitecontentList,
    'fetchWebsiteinfoSitecontentItem': fetchWebsiteinfoSitecontentItem,
    'createWebsiteinfoSitecontentItem': createWebsiteinfoSitecontentItem,
    'updateWebsiteinfoSitecontentItem': updateWebsiteinfoSitecontentItem,
    'deleteWebsiteinfoSitecontentItem': deleteWebsiteinfoSitecontentItem,

    'fetchOpenresourceResourceList': fetchOpenresourceResourceList,
    'fetchOpenresourceResourceItem': fetchOpenresourceResourceItem,
    'createOpenresourceResourceItem': createOpenresourceResourceItem,
    'updateOpenresourceResourceItem': updateOpenresourceResourceItem,
    'deleteOpenresourceResourceItem': deleteOpenresourceResourceItem,

    'fetchShowcaseResourceList': fetchShowcaseResourceList,
    'fetchShowcaseResourceItem': fetchShowcaseResourceItem,
    'createShowcaseResourceItem': createShowcaseResourceItem,
    'updateShowcaseResourceItem': updateShowcaseResourceItem,
    'deleteShowcaseResourceItem': deleteShowcaseResourceItem,

    'fetchCommunityFollowList': fetchCommunityFollowList,
    'fetchCommunityFollowItem': fetchCommunityFollowItem,
    'createCommunityFollowItem': createCommunityFollowItem,
    'updateCommunityFollowItem': updateCommunityFollowItem,
    'deleteCommunityFollowItem': deleteCommunityFollowItem,

    'fetchCommunityGeneralpostList': fetchCommunityGeneralpostList,
    'fetchCommunityGeneralpostItem': fetchCommunityGeneralpostItem,
    'createCommunityGeneralpostItem': createCommunityGeneralpostItem,
    'updateCommunityGeneralpostItem': updateCommunityGeneralpostItem,
    'deleteCommunityGeneralpostItem': deleteCommunityGeneralpostItem,

    'fetchCommunityPostList': fetchCommunityPostList,
    'fetchCommunityPostItem': fetchCommunityPostItem,
    'createCommunityPostItem': createCommunityPostItem,
    'updateCommunityPostItem': updateCommunityPostItem,
    'deleteCommunityPostItem': deleteCommunityPostItem,

    'fetchCommunityCommentList': fetchCommunityCommentList,
    'fetchCommunityCommentItem': fetchCommunityCommentItem,
    'createCommunityCommentItem': createCommunityCommentItem,
    'updateCommunityCommentItem': updateCommunityCommentItem,
    'deleteCommunityCommentItem': deleteCommunityCommentItem,

    'fetchCommunityThreadList': fetchCommunityThreadList,
    'fetchCommunityThreadItem': fetchCommunityThreadItem,
    'createCommunityThreadItem': createCommunityThreadItem,
    'updateCommunityThreadItem': updateCommunityThreadItem,
    'deleteCommunityThreadItem': deleteCommunityThreadItem,

    'fetchCommunityLikeList': fetchCommunityLikeList,
    'fetchCommunityLikeItem': fetchCommunityLikeItem,
    'createCommunityLikeItem': createCommunityLikeItem,
    'updateCommunityLikeItem': updateCommunityLikeItem,
    'deleteCommunityLikeItem': deleteCommunityLikeItem,

    'fetchOrganizationOrganizationList': fetchOrganizationOrganizationList,
    'fetchOrganizationOrganizationItem': fetchOrganizationOrganizationItem,
    'createOrganizationOrganizationItem': createOrganizationOrganizationItem,
    'updateOrganizationOrganizationItem': updateOrganizationOrganizationItem,
    'deleteOrganizationOrganizationItem': deleteOrganizationOrganizationItem,

    'fetchDriveAlloweduserspaceList': fetchDriveAlloweduserspaceList,
    'fetchDriveAlloweduserspaceItem': fetchDriveAlloweduserspaceItem,
    'createDriveAlloweduserspaceItem': createDriveAlloweduserspaceItem,
    'updateDriveAlloweduserspaceItem': updateDriveAlloweduserspaceItem,
    'deleteDriveAlloweduserspaceItem': deleteDriveAlloweduserspaceItem,

    'fetchDriveFolderList': fetchDriveFolderList,
    'fetchDriveFolderItem': fetchDriveFolderItem,
    'createDriveFolderItem': createDriveFolderItem,
    'updateDriveFolderItem': updateDriveFolderItem,
    'deleteDriveFolderItem': deleteDriveFolderItem,

    'fetchDriveFileList': fetchDriveFileList,
    'fetchDriveFileItem': fetchDriveFileItem,
    'createDriveFileItem': createDriveFileItem,
    'updateDriveFileItem': updateDriveFileItem,
    'deleteDriveFileItem': deleteDriveFileItem,

    'fetchDriveSharelinkList': fetchDriveSharelinkList,
    'fetchDriveSharelinkItem': fetchDriveSharelinkItem,
    'createDriveSharelinkItem': createDriveSharelinkItem,
    'updateDriveSharelinkItem': updateDriveSharelinkItem,
    'deleteDriveSharelinkItem': deleteDriveSharelinkItem,

    'fetchDriveMessageList': fetchDriveMessageList,
    'fetchDriveMessageItem': fetchDriveMessageItem,
    'createDriveMessageItem': createDriveMessageItem,
    'updateDriveMessageItem': updateDriveMessageItem,
    'deleteDriveMessageItem': deleteDriveMessageItem,

    'fetchCoursematerialTopicList': fetchCoursematerialTopicList,
    'fetchCoursematerialTopicItem': fetchCoursematerialTopicItem,
    'createCoursematerialTopicItem': createCoursematerialTopicItem,
    'updateCoursematerialTopicItem': updateCoursematerialTopicItem,
    'deleteCoursematerialTopicItem': deleteCoursematerialTopicItem,

    'fetchCoursematerialSubjectList': fetchCoursematerialSubjectList,
    'fetchCoursematerialSubjectItem': fetchCoursematerialSubjectItem,
    'createCoursematerialSubjectItem': createCoursematerialSubjectItem,
    'updateCoursematerialSubjectItem': updateCoursematerialSubjectItem,
    'deleteCoursematerialSubjectItem': deleteCoursematerialSubjectItem,

    'fetchCoursematerialCourseList': fetchCoursematerialCourseList,
    'fetchCoursematerialCourseItem': fetchCoursematerialCourseItem,
    'createCoursematerialCourseItem': createCoursematerialCourseItem,
    'updateCoursematerialCourseItem': updateCoursematerialCourseItem,
    'deleteCoursematerialCourseItem': deleteCoursematerialCourseItem,

    'fetchCoursematerialCourseintroList': fetchCoursematerialCourseintroList,
    'fetchCoursematerialCourseintroItem': fetchCoursematerialCourseintroItem,
    'createCoursematerialCourseintroItem': createCoursematerialCourseintroItem,
    'updateCoursematerialCourseintroItem': updateCoursematerialCourseintroItem,
    'deleteCoursematerialCourseintroItem': deleteCoursematerialCourseintroItem,

    'fetchCoursematerialCourselabelList': fetchCoursematerialCourselabelList,
    'fetchCoursematerialCourselabelItem': fetchCoursematerialCourselabelItem,
    'createCoursematerialCourselabelItem': createCoursematerialCourselabelItem,
    'updateCoursematerialCourselabelItem': updateCoursematerialCourselabelItem,
    'deleteCoursematerialCourselabelItem': deleteCoursematerialCourselabelItem,

    'fetchCoursematerialModuleList': fetchCoursematerialModuleList,
    'fetchCoursematerialModuleItem': fetchCoursematerialModuleItem,
    'createCoursematerialModuleItem': createCoursematerialModuleItem,
    'updateCoursematerialModuleItem': updateCoursematerialModuleItem,
    'deleteCoursematerialModuleItem': deleteCoursematerialModuleItem,

    'fetchCoursematerialResourceList': fetchCoursematerialResourceList,
    'fetchCoursematerialResourceItem': fetchCoursematerialResourceItem,
    'createCoursematerialResourceItem': createCoursematerialResourceItem,
    'updateCoursematerialResourceItem': updateCoursematerialResourceItem,
    'deleteCoursematerialResourceItem': deleteCoursematerialResourceItem,

    'fetchCoursematerialLessonList': fetchCoursematerialLessonList,
    'fetchCoursematerialLessonItem': fetchCoursematerialLessonItem,
    'createCoursematerialLessonItem': createCoursematerialLessonItem,
    'updateCoursematerialLessonItem': updateCoursematerialLessonItem,
    'deleteCoursematerialLessonItem': deleteCoursematerialLessonItem,

    'fetchCoursematerialAssignmentList': fetchCoursematerialAssignmentList,
    'fetchCoursematerialAssignmentItem': fetchCoursematerialAssignmentItem,
    'createCoursematerialAssignmentItem': createCoursematerialAssignmentItem,
    'updateCoursematerialAssignmentItem': updateCoursematerialAssignmentItem,
    'deleteCoursematerialAssignmentItem': deleteCoursematerialAssignmentItem,

    'fetchCoursematerialSubmissionList': fetchCoursematerialSubmissionList,
    'fetchCoursematerialSubmissionItem': fetchCoursematerialSubmissionItem,
    'createCoursematerialSubmissionItem': createCoursematerialSubmissionItem,
    'updateCoursematerialSubmissionItem': updateCoursematerialSubmissionItem,
    'deleteCoursematerialSubmissionItem': deleteCoursematerialSubmissionItem,

    'fetchCoursematerialInsightsList': fetchCoursematerialInsightsList,
    'fetchCoursematerialInsightsItem': fetchCoursematerialInsightsItem,
    'createCoursematerialInsightsItem': createCoursematerialInsightsItem,
    'updateCoursematerialInsightsItem': updateCoursematerialInsightsItem,
    'deleteCoursematerialInsightsItem': deleteCoursematerialInsightsItem,

    'fetchMonetizationSubscriptionplanList': fetchMonetizationSubscriptionplanList,
    'fetchMonetizationSubscriptionplanItem': fetchMonetizationSubscriptionplanItem,
    'createMonetizationSubscriptionplanItem': createMonetizationSubscriptionplanItem,
    'updateMonetizationSubscriptionplanItem': updateMonetizationSubscriptionplanItem,
    'deleteMonetizationSubscriptionplanItem': deleteMonetizationSubscriptionplanItem,

    'fetchMonetizationGroupmembershipList': fetchMonetizationGroupmembershipList,
    'fetchMonetizationGroupmembershipItem': fetchMonetizationGroupmembershipItem,
    'createMonetizationGroupmembershipItem': createMonetizationGroupmembershipItem,
    'updateMonetizationGroupmembershipItem': updateMonetizationGroupmembershipItem,
    'deleteMonetizationGroupmembershipItem': deleteMonetizationGroupmembershipItem,

    'fetchMonetizationPurchasedcourseList': fetchMonetizationPurchasedcourseList,
    'fetchMonetizationPurchasedcourseItem': fetchMonetizationPurchasedcourseItem,
    'createMonetizationPurchasedcourseItem': createMonetizationPurchasedcourseItem,
    'updateMonetizationPurchasedcourseItem': updateMonetizationPurchasedcourseItem,
    'deleteMonetizationPurchasedcourseItem': deleteMonetizationPurchasedcourseItem,

    'fetchMonetizationNewslettersubscriptionList': fetchMonetizationNewslettersubscriptionList,
    'fetchMonetizationNewslettersubscriptionItem': fetchMonetizationNewslettersubscriptionItem,
    'createMonetizationNewslettersubscriptionItem': createMonetizationNewslettersubscriptionItem,
    'updateMonetizationNewslettersubscriptionItem': updateMonetizationNewslettersubscriptionItem,
    'deleteMonetizationNewslettersubscriptionItem': deleteMonetizationNewslettersubscriptionItem,

    'fetchMonetizationSeriessubscriptionList': fetchMonetizationSeriessubscriptionList,
    'fetchMonetizationSeriessubscriptionItem': fetchMonetizationSeriessubscriptionItem,
    'createMonetizationSeriessubscriptionItem': createMonetizationSeriessubscriptionItem,
    'updateMonetizationSeriessubscriptionItem': updateMonetizationSeriessubscriptionItem,
    'deleteMonetizationSeriessubscriptionItem': deleteMonetizationSeriessubscriptionItem,

    'fetchMonetizationForumsubscriptionList': fetchMonetizationForumsubscriptionList,
    'fetchMonetizationForumsubscriptionItem': fetchMonetizationForumsubscriptionItem,
    'createMonetizationForumsubscriptionItem': createMonetizationForumsubscriptionItem,
    'updateMonetizationForumsubscriptionItem': updateMonetizationForumsubscriptionItem,
    'deleteMonetizationForumsubscriptionItem': deleteMonetizationForumsubscriptionItem,

    'fetchMonetizationEventbookingList': fetchMonetizationEventbookingList,
    'fetchMonetizationEventbookingItem': fetchMonetizationEventbookingItem,
    'createMonetizationEventbookingItem': createMonetizationEventbookingItem,
    'updateMonetizationEventbookingItem': updateMonetizationEventbookingItem,
    'deleteMonetizationEventbookingItem': deleteMonetizationEventbookingItem,

    'fetchQuizQuizsetList': fetchQuizQuizsetList,
    'fetchQuizQuizsetItem': fetchQuizQuizsetItem,
    'createQuizQuizsetItem': createQuizQuizsetItem,
    'updateQuizQuizsetItem': updateQuizQuizsetItem,
    'deleteQuizQuizsetItem': deleteQuizQuizsetItem,

    'fetchQuizQuizitemList': fetchQuizQuizitemList,
    'fetchQuizQuizitemItem': fetchQuizQuizitemItem,
    'createQuizQuizitemItem': createQuizQuizitemItem,
    'updateQuizQuizitemItem': updateQuizQuizitemItem,
    'deleteQuizQuizitemItem': deleteQuizQuizitemItem,

    'fetchQuizQuizresponseList': fetchQuizQuizresponseList,
    'fetchQuizQuizresponseItem': fetchQuizQuizresponseItem,
    'createQuizQuizresponseItem': createQuizQuizresponseItem,
    'updateQuizQuizresponseItem': updateQuizQuizresponseItem,
    'deleteQuizQuizresponseItem': deleteQuizQuizresponseItem,

    'fetchQuizQuizsubmissionList': fetchQuizQuizsubmissionList,
    'fetchQuizQuizsubmissionItem': fetchQuizQuizsubmissionItem,
    'createQuizQuizsubmissionItem': createQuizQuizsubmissionItem,
    'updateQuizQuizsubmissionItem': updateQuizQuizsubmissionItem,
    'deleteQuizQuizsubmissionItem': deleteQuizQuizsubmissionItem,

    'fetchQuizQuizcorrectanswerList': fetchQuizQuizcorrectanswerList,
    'fetchQuizQuizcorrectanswerItem': fetchQuizQuizcorrectanswerItem,
    'createQuizQuizcorrectanswerItem': createQuizQuizcorrectanswerItem,
    'updateQuizQuizcorrectanswerItem': updateQuizQuizcorrectanswerItem,
    'deleteQuizQuizcorrectanswerItem': deleteQuizQuizcorrectanswerItem,

    'fetchUsermanagementStarredList': fetchUsermanagementStarredList,
    'fetchUsermanagementStarredItem': fetchUsermanagementStarredItem,
    'createUsermanagementStarredItem': createUsermanagementStarredItem,
    'updateUsermanagementStarredItem': updateUsermanagementStarredItem,
    'deleteUsermanagementStarredItem': deleteUsermanagementStarredItem,

    'fetchUsermanagementCourseenrollmentprogressList': fetchUsermanagementCourseenrollmentprogressList,
    'fetchUsermanagementCourseenrollmentprogressItem': fetchUsermanagementCourseenrollmentprogressItem,
    'createUsermanagementCourseenrollmentprogressItem': createUsermanagementCourseenrollmentprogressItem,
    'updateUsermanagementCourseenrollmentprogressItem': updateUsermanagementCourseenrollmentprogressItem,
    'deleteUsermanagementCourseenrollmentprogressItem': deleteUsermanagementCourseenrollmentprogressItem,

    'fetchUsermanagementSeriesinterestList': fetchUsermanagementSeriesinterestList,
    'fetchUsermanagementSeriesinterestItem': fetchUsermanagementSeriesinterestItem,
    'createUsermanagementSeriesinterestItem': createUsermanagementSeriesinterestItem,
    'updateUsermanagementSeriesinterestItem': updateUsermanagementSeriesinterestItem,
    'deleteUsermanagementSeriesinterestItem': deleteUsermanagementSeriesinterestItem,

    'fetchUsermanagementChannelmemberList': fetchUsermanagementChannelmemberList,
    'fetchUsermanagementChannelmemberItem': fetchUsermanagementChannelmemberItem,
    'createUsermanagementChannelmemberItem': createUsermanagementChannelmemberItem,
    'updateUsermanagementChannelmemberItem': updateUsermanagementChannelmemberItem,
    'deleteUsermanagementChannelmemberItem': deleteUsermanagementChannelmemberItem,

    'fetchUsermanagementForummemberList': fetchUsermanagementForummemberList,
    'fetchUsermanagementForummemberItem': fetchUsermanagementForummemberItem,
    'createUsermanagementForummemberItem': createUsermanagementForummemberItem,
    'updateUsermanagementForummemberItem': updateUsermanagementForummemberItem,
    'deleteUsermanagementForummemberItem': deleteUsermanagementForummemberItem,

    'fetchUsermanagementMemberList': fetchUsermanagementMemberList,
    'fetchUsermanagementMemberItem': fetchUsermanagementMemberItem,
    'createUsermanagementMemberItem': createUsermanagementMemberItem,
    'updateUsermanagementMemberItem': updateUsermanagementMemberItem,
    'deleteUsermanagementMemberItem': deleteUsermanagementMemberItem,

    'fetchUsermanagementSecurityquestionList': fetchUsermanagementSecurityquestionList,
    'fetchUsermanagementSecurityquestionItem': fetchUsermanagementSecurityquestionItem,
    'createUsermanagementSecurityquestionItem': createUsermanagementSecurityquestionItem,
    'updateUsermanagementSecurityquestionItem': updateUsermanagementSecurityquestionItem,
    'deleteUsermanagementSecurityquestionItem': deleteUsermanagementSecurityquestionItem,

    'fetchUsermanagementOtpverificationList': fetchUsermanagementOtpverificationList,
    'fetchUsermanagementOtpverificationItem': fetchUsermanagementOtpverificationItem,
    'createUsermanagementOtpverificationItem': createUsermanagementOtpverificationItem,
    'updateUsermanagementOtpverificationItem': updateUsermanagementOtpverificationItem,
    'deleteUsermanagementOtpverificationItem': deleteUsermanagementOtpverificationItem,

    'fetchUsermanagementGeneralprofileList': fetchUsermanagementGeneralprofileList,
    'fetchUsermanagementGeneralprofileItem': fetchUsermanagementGeneralprofileItem,
    'createUsermanagementGeneralprofileItem': createUsermanagementGeneralprofileItem,
    'updateUsermanagementGeneralprofileItem': updateUsermanagementGeneralprofileItem,
    'deleteUsermanagementGeneralprofileItem': deleteUsermanagementGeneralprofileItem,

    'fetchUsermanagementBadgeList': fetchUsermanagementBadgeList,
    'fetchUsermanagementBadgeItem': fetchUsermanagementBadgeItem,
    'createUsermanagementBadgeItem': createUsermanagementBadgeItem,
    'updateUsermanagementBadgeItem': updateUsermanagementBadgeItem,
    'deleteUsermanagementBadgeItem': deleteUsermanagementBadgeItem,

    'fetchNoteNoteList': fetchNoteNoteList,
    'fetchNoteNoteItem': fetchNoteNoteItem,
    'createNoteNoteItem': createNoteNoteItem,
    'updateNoteNoteItem': updateNoteNoteItem,
    'deleteNoteNoteItem': deleteNoteNoteItem,

    'fetchResearchandarticleCategoryList': fetchResearchandarticleCategoryList,
    'fetchResearchandarticleCategoryItem': fetchResearchandarticleCategoryItem,
    'createResearchandarticleCategoryItem': createResearchandarticleCategoryItem,
    'updateResearchandarticleCategoryItem': updateResearchandarticleCategoryItem,
    'deleteResearchandarticleCategoryItem': deleteResearchandarticleCategoryItem,

    'fetchResearchandarticleResearchpaperList': fetchResearchandarticleResearchpaperList,
    'fetchResearchandarticleResearchpaperItem': fetchResearchandarticleResearchpaperItem,
    'createResearchandarticleResearchpaperItem': createResearchandarticleResearchpaperItem,
    'updateResearchandarticleResearchpaperItem': updateResearchandarticleResearchpaperItem,
    'deleteResearchandarticleResearchpaperItem': deleteResearchandarticleResearchpaperItem,

    'fetchResearchandarticleArticleList': fetchResearchandarticleArticleList,
    'fetchResearchandarticleArticleItem': fetchResearchandarticleArticleItem,
    'createResearchandarticleArticleItem': createResearchandarticleArticleItem,
    'updateResearchandarticleArticleItem': updateResearchandarticleArticleItem,
    'deleteResearchandarticleArticleItem': deleteResearchandarticleArticleItem,

    'fetchResearchandarticleCommentList': fetchResearchandarticleCommentList,
    'fetchResearchandarticleCommentItem': fetchResearchandarticleCommentItem,
    'createResearchandarticleCommentItem': createResearchandarticleCommentItem,
    'updateResearchandarticleCommentItem': updateResearchandarticleCommentItem,
    'deleteResearchandarticleCommentItem': deleteResearchandarticleCommentItem,

    'fetchEventEventList': fetchEventEventList,
    'fetchEventEventItem': fetchEventEventItem,
    'createEventEventItem': createEventEventItem,
    'updateEventEventItem': updateEventEventItem,
    'deleteEventEventItem': deleteEventEventItem,

    'fetchEventTicketList': fetchEventTicketList,
    'fetchEventTicketItem': fetchEventTicketItem,
    'createEventTicketItem': createEventTicketItem,
    'updateEventTicketItem': updateEventTicketItem,
    'deleteEventTicketItem': deleteEventTicketItem,

    'fetchEventEnrollmentformList': fetchEventEnrollmentformList,
    'fetchEventEnrollmentformItem': fetchEventEnrollmentformItem,
    'createEventEnrollmentformItem': createEventEnrollmentformItem,
    'updateEventEnrollmentformItem': updateEventEnrollmentformItem,
    'deleteEventEnrollmentformItem': deleteEventEnrollmentformItem,

    'fetchEventEnrollmentinfoList': fetchEventEnrollmentinfoList,
    'fetchEventEnrollmentinfoItem': fetchEventEnrollmentinfoItem,
    'createEventEnrollmentinfoItem': createEventEnrollmentinfoItem,
    'updateEventEnrollmentinfoItem': updateEventEnrollmentinfoItem,
    'deleteEventEnrollmentinfoItem': deleteEventEnrollmentinfoItem,

    'fetchEventFormfieldList': fetchEventFormfieldList,
    'fetchEventFormfieldItem': fetchEventFormfieldItem,
    'createEventFormfieldItem': createEventFormfieldItem,
    'updateEventFormfieldItem': updateEventFormfieldItem,
    'deleteEventFormfieldItem': deleteEventFormfieldItem,

    'fetchEventCouponList': fetchEventCouponList,
    'fetchEventCouponItem': fetchEventCouponItem,
    'createEventCouponItem': createEventCouponItem,
    'updateEventCouponItem': updateEventCouponItem,
    'deleteEventCouponItem': deleteEventCouponItem,

    'fetchMeetConferenceList': fetchMeetConferenceList,
    'fetchMeetConferenceItem': fetchMeetConferenceItem,
    'createMeetConferenceItem': createMeetConferenceItem,
    'updateMeetConferenceItem': updateMeetConferenceItem,
    'deleteMeetConferenceItem': deleteMeetConferenceItem,

    'fetchMeetRoomList': fetchMeetRoomList,
    'fetchMeetRoomItem': fetchMeetRoomItem,
    'createMeetRoomItem': createMeetRoomItem,
    'updateMeetRoomItem': updateMeetRoomItem,
    'deleteMeetRoomItem': deleteMeetRoomItem,

    'fetchMeetConferenceuserList': fetchMeetConferenceuserList,
    'fetchMeetConferenceuserItem': fetchMeetConferenceuserItem,
    'createMeetConferenceuserItem': createMeetConferenceuserItem,
    'updateMeetConferenceuserItem': updateMeetConferenceuserItem,
    'deleteMeetConferenceuserItem': deleteMeetConferenceuserItem,

    'fetchExtraExclusiveaccesscontactsList': fetchExtraExclusiveaccesscontactsList,
    'fetchExtraExclusiveaccesscontactsItem': fetchExtraExclusiveaccesscontactsItem,
    'createExtraExclusiveaccesscontactsItem': createExtraExclusiveaccesscontactsItem,
    'updateExtraExclusiveaccesscontactsItem': updateExtraExclusiveaccesscontactsItem,
    'deleteExtraExclusiveaccesscontactsItem': deleteExtraExclusiveaccesscontactsItem,

    'fetchUserauthSecuritychallengeList': fetchUserauthSecuritychallengeList,
    'fetchUserauthSecuritychallengeItem': fetchUserauthSecuritychallengeItem,
    'createUserauthSecuritychallengeItem': createUserauthSecuritychallengeItem,
    'updateUserauthSecuritychallengeItem': updateUserauthSecuritychallengeItem,
    'deleteUserauthSecuritychallengeItem': deleteUserauthSecuritychallengeItem,
}